import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import { Modal, Button, Typography, Form, InputNumber } from 'antd'
import { toast } from 'react-toastify'
import PatternNumber from '~utils/PatternNumber'
import NumberWithCommas from '~utils/NumberWithCommas'
import {
    TransferAdd
} from '~types/transfer'
import { requestAuthInit } from '~store/auth/auth.actions'
import { ThunkDispatch } from '~types/store'
import ServiceAPI from '~services/services'

interface ModalProps {
    close: () => void
    isOpen: boolean

    username: string
    maxWithdraw: number
    onloadData: () => void
}

const Withdraw: FC<ModalProps> = ({ isOpen, close, username, maxWithdraw, onloadData }) => {
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()

    const insertData = async (values: TransferAdd) => {
        try {
            const res = await ServiceAPI.setUsersWithdraw(username, values)
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
            onloadData()
            close()
        } catch ({ message }) {
            toast.error(message as string)
            setBtnLoading(false)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: TransferAdd) => {
                setBtnLoading(true)
                insertData(values)
            })
            .catch((errorInfo) => {

            });
    };

    useEffect(() => {
        if (isOpen) {
            form.resetFields();
        }
    }, [form, isOpen])


    return (
        <Modal
            title={`Withdraw`}
            open={isOpen}
            onCancel={close}
            onOk={handleFormSubmit}
            centered
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .2}
            footer={[
                <Button key="submit" type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i>Save</Button>,
                <Button key="close" onClick={close}>Close</Button>,
            ]}>
            <Typography.Title level={5}>
                Username : {username}
            </Typography.Title>
            <Form
                form={form}
                name="withdraw"
                layout="vertical">
                <Form.Item
                    name="amount"
                    label="Withdraw"
                    rules={[
                        { required: true, message: 'Please input your withdraw!' },
                        { pattern: PatternNumber, message: 'Please enter a number only!' },
                        { type: 'number', min: 1, max: maxWithdraw, message: `Please enter a number between 1 and ${NumberWithCommas(maxWithdraw)}!` }
                    ]}
                    extra={`Min 1, Max ${NumberWithCommas(maxWithdraw)}`}
                    hasFeedback>
                    <InputNumber
                        placeholder="Withdraw"
                        inputMode="numeric"
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/\.(?=\d{0,2}$)/g, ".")}
                        parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                        controls={false}
                        onKeyPress={(event) => {
                            if (!/^[0-9.,\b]+$/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}


export default Withdraw