import { useEffect, useMemo, useState } from 'react'
import TitleBar from '~components/Elements/TitleBar'
import FormattedMessage from '~components/Elements/FormattedMessage'
import { Row, Col, Typography, Tabs, Descriptions, Empty, Divider, } from 'antd'
// import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
// import * as ChartGoogle from "react-google-charts"
import { ApplicationProviderState, ApplicationState } from '~store/index'
import { useSelector } from 'react-redux'
import { getProvider, getGamesType } from '~utils/games'
import AmountCard from '~components/Elements/AmountCard'
import useFetchDashboard from '~hooks/useFetchDashboard'
import useDateRangeEmpty from '~hooks/useDateRangeEmpty'
import NumberWithCommas from '~utils/NumberWithCommas'
import Turnover from '~components/Dashboard/Turnover'
import StakeCount from '~components/Dashboard/StakeCount'
import MemberWinloss from '~components/Dashboard/MemberWinloss'
import AgentWinloss from '~components/Dashboard/AgentWinloss'

const Dashboard = () => {

    const authState = useSelector((state: ApplicationState) => state.auth)
    const { providers: providerState } = useSelector((state: ApplicationProviderState) => state.providers)
    const products: any = authState.data.user?.products
    const [dataProducts, setDataProducts] = useState<any>([])


    const [dataDashboard, setDataDashboard] = useState<any>([])
    const { dateRange, setDateRange } = useDateRangeEmpty()
    const { data, mutate, isLoading } = useFetchDashboard({
        startDate: dateRange.start,
        endDate: dateRange.end,
    })

    // const listProducts = (gameType: string) => {
    //     if (dataProducts.length > 0) {
    //         const products = Object.keys(dataProducts).map((key: string, index: any) => {
    //             if (!dataProducts[key].enabled) {
    //                 return []
    //             }
    //             const labelCheckbox = dataProducts[key]
    //             const res = getProvider(labelCheckbox.key, providerState.data)
    //             res.enabled = dataProducts[key].enabled
    //             res.ownPT = dataProducts[key].ownPT
    //             return res
    //         })
    //         return products.filter((product: any) => product.type === gameType)
    //     }
    // }

    const TabTypeProducts = Object.keys(getGamesType).map((item: string, index: any) => {
        // const productList: any = listProducts(item.toLocaleUpperCase())
        const productList: any = dataProducts.filter((product: any) => product.type === item.toLocaleUpperCase())
        const productType = (Object.values(getGamesType)[index]).toLocaleUpperCase()

        const productElement = productList && productList.length > 0 ? productList.map((product: any) => {
            if (!product.enabled) {
                return ""
            }
            return (
                <Descriptions.Item label={product.name} key={product.key}>
                    {product.ownPT}%
                </Descriptions.Item>
            )
        }) : "";
        return {
            label: productType,
            key: index,
            children: (
                (productList && productList.length > 0) ? (
                    <Descriptions column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }} bordered size="small">
                        <Descriptions.Item label={<span style={{ fontWeight: "bold" }}>Game Name</span>} key={0}>
                            <span style={{ fontWeight: "bold" }}>Percentage</span>
                        </Descriptions.Item>
                        {productElement}
                    </Descriptions>
                ) : <Empty key={`Empty ${productType}`} />
            ),
            disabled: productList && productList.length > 0 ? false : true
        }
    })


    useEffect(() => {
        setDataProducts([])
        if (products !== undefined && Object.keys(products).length > 0 && providerState !== undefined && Object.keys(providerState.data).length > 0) {
            const productIn = Object.keys(products).map((key: string) => {
                const provider = getProvider(key, providerState.data)
                return {
                    "key": key,
                    "name": provider.name,
                    "type": provider.type,
                    "ownPT": products[key].ownPT,
                    "enabled": products[key].enabled,
                }
            })
            setDataProducts(productIn);
        }
    }, [products, providerState])

    useEffect(() => {
        if (data !== undefined && Object.keys(data).length > 0) {
            setDataDashboard(data)
        }
    }, [data])


    useMemo(() => {
        mutate()
    }, [setDateRange])// eslint-disable-line

    // const [theme, setTheme] = useState(localStorage.getItem("theme"))
    // const chart1 = {
    //     series: [{
    //         name: 'Net Profit',
    //         data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
    //     }, {
    //         name: 'Revenue',
    //         data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
    //     }, {
    //         name: 'Free Cash Flow',
    //         data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
    //     }],
    //     options: {
    //         chart: {
    //             type: 'bar',
    //             height: 350
    //         },
    //         plotOptions: {
    //             bar: {
    //                 horizontal: false,
    //                 columnWidth: '55%',
    //                 endingShape: 'rounded'
    //             },
    //         },
    //         dataLabels: {
    //             enabled: false
    //         },
    //         stroke: {
    //             show: true,
    //             width: 2,
    //             colors: ['transparent']
    //         },
    //         xaxis: {
    //             categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    //         },
    //         yaxis: {
    //             title: {
    //                 text: '$ (thousands)'
    //             }
    //         },
    //         fill: {
    //             opacity: 1
    //         },
    //         tooltip: {
    //             y: {
    //                 formatter: function (val: any) {
    //                     return "$ " + val + " thousands"
    //                 }
    //             }
    //         }
    //     },
    // }

    // const chart2 = {
    //     series: [{
    //         data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
    //     }],
    //     options: {
    //         chart: {
    //             type: 'bar',
    //             height: 350
    //         },
    //         plotOptions: {
    //             bar: {
    //                 borderRadius: 4,
    //                 horizontal: true,
    //             }
    //         },
    //         dataLabels: {
    //             enabled: false
    //         },
    //         xaxis: {
    //             categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
    //                 'United States', 'China', 'Germany'
    //             ],
    //         }
    //     },
    // }

    // const dataSource = [
    //     {
    //         key: '1',
    //         name: 'Mike',
    //         age: 32,
    //         address: '10 Downing Street',
    //     },
    //     {
    //         key: '2',
    //         name: 'John',
    //         age: 42,
    //         address: '10 Downing Street',
    //     },
    //     {
    //         key: '3',
    //         name: 'John',
    //         age: 42,
    //         address: '10 Downing Street',
    //     },
    //     {
    //         key: '4',
    //         name: 'John',
    //         age: 42,
    //         address: '10 Downing Street',
    //     },
    //     {
    //         key: '5',
    //         name: 'John',
    //         age: 42,
    //         address: '10 Downing Street',
    //     },
    // ];

    // const columns = [
    //     {
    //         title: 'Name',
    //         dataIndex: 'name',
    //         key: 'name',
    //     },
    //     {
    //         title: 'Age',
    //         dataIndex: 'age',
    //         key: 'age',
    //     },
    //     {
    //         title: 'Address',
    //         dataIndex: 'address',
    //         key: 'address',
    //     },
    // ];

    // const dataGeo = [
    //     ["Country", "Popularity"],
    //     ["Germany", 200],
    //     ["United States", 1100],
    //     ["Brazil", 1200],
    //     ["Canada", 900],
    //     ["France", 1000],
    //     ["RU", 1700],
    //     ["Thailand", 2000]
    // ];

    // useEffect(() => {
    //     // setTheme(localStorage.getItem("theme"))
    // }, [localStorage.getItem("theme")]) // eslint-disable-line

    return (
        <>
            <TitleBar title={<FormattedMessage id={'main.dashboard'} defaultMessage={'Home'} />} subTitle={""} />
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} lg={24} className="">
                    <Typography.Title level={5}>Summary</Typography.Title>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} lg={8} className="mt-1 mt-xl-0">
                            <AmountCard
                                icon='fa-solid fa-plus-circle'
                                theme='success'
                                amount=''
                                number={NumberWithCommas(dataDashboard?.totalDeposit, 2)}
                                amountType='Total Deposit'
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} sm={12} lg={8} className="mt-xl-0">
                            <AmountCard
                                icon='fa-solid fa-minus-circle'
                                theme='danger'
                                amount=''
                                number={NumberWithCommas(dataDashboard?.totalWithdraw, 2)}
                                amountType='Total Withdraw'
                                loading={isLoading}
                            />
                        </Col>
                        <Col xs={24} sm={12} lg={8} className="mt-xl-0">
                            <AmountCard
                                icon='fa-solid fa-user-plus'
                                theme='info'
                                amount=''
                                number={NumberWithCommas(dataDashboard?.registeredUserCount)}
                                amountType='Registered User'
                                loading={isLoading}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} lg={12} className="mt-xl-0">
                            <Turnover
                                dataSource={dataDashboard?.charts}
                                isLoading={isLoading}
                            />
                        </Col>
                        <Col xs={24} sm={24} lg={12} className="mt-xl-0">
                            <StakeCount
                                dataSource={dataDashboard?.charts}
                                isLoading={isLoading}
                            />
                        </Col>
                        <Col xs={24} sm={24} lg={12} className="mt-xl-0">
                            <MemberWinloss
                                dataSource={dataDashboard?.charts}
                                isLoading={isLoading}
                            />
                        </Col>
                        <Col xs={24} sm={24} lg={12} className="mt-xl-0">
                            <AgentWinloss
                                dataSource={dataDashboard?.charts}
                                isLoading={isLoading}
                            />
                        </Col>
                    </Row>
                </Col>
                <Divider />
                <Col xs={24} sm={24} lg={24} className="mt-3 mt-xl-0">
                    <Typography.Title level={5}>Products</Typography.Title>
                    <Tabs defaultActiveKey="0" centered items={TabTypeProducts} tabPosition={"top"} />
                </Col>
            </Row>
            {/* 
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} lg={12} className="mt-3 mt-xl-0">
                    <Chart
                        type="bar"
                        options={chart1.options as object}
                        series={chart1.series}
                        height={350}
                    />
                </Col>
                <Col xs={24} sm={12} lg={12} className="mt-3 mt-xl-0">
                    <Chart
                        type="bar"
                        options={chart2.options as object}
                        series={chart2.series}
                        height={350}
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} lg={12} className="mt-3 mt-xl-0">
                    <Card>
                        <Statistic
                            title="Active"
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={12} lg={12} className="mt-3 mt-xl-0">
                    <Card>
                        <Statistic
                            title="Idle"
                            value={9.3}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={12} lg={12} className="mt-3 mt-xl-0">
                    <Card>
                        <Statistic
                            title="Active"
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col xs={24} sm={12} lg={12} className="mt-3 mt-xl-0">
                    <Card>
                        <Statistic
                            title="Idle"
                            value={9.3}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={16} className="mt-3">
                <Col xs={24} sm={24} lg={24} className="mt-3 mt-xl-0">
                    <ChartGoogle.Chart chartType="GeoChart" width="100%" height="400px" data={dataGeo} options={{ backgroundColor: theme === "light" ? "#fff" : "#141414" }} />
                </Col>
            </Row>
            <Row gutter={16} className="mt-3">
                <Col xs={24} sm={12} lg={12} className="mt-3 mt-xl-0">
                    <Table dataSource={dataSource} columns={columns} />
                </Col>
                <Col xs={24} sm={12} lg={12} className="mt-3 mt-xl-0">
                    <Table dataSource={dataSource} columns={columns} />
                </Col>
            </Row> */}
        </>
    );
};

export default Dashboard