import React, { FC } from 'react'
import { Table, Spin, Skeleton, Empty } from 'antd'
// import { LoadingOutlined } from '@ant-design/icons'
import { nanoid } from 'nanoid'
import ScrollToTopPagination from '~utils/ScrollToTopPagination'

interface TableMiniProps {
    columns: any
    dataSource: any
    loading?: boolean
    title?: string
    footer?: string
}

const TableAnnouncer: FC<TableMiniProps> = ({ columns, dataSource, loading, title, footer }) => {
    let options: any = {}
    if (title) {
        options['title'] = () => title
    }
    if (footer) {
        options['footer'] = () => footer
    }

    return (
        <>
            <Spin spinning={loading} tip="Loading..." size="large" >
                <Table
                    columns={columns as any}
                    dataSource={dataSource}
                    rowKey={() => nanoid()}
                    size={'small'}
                    bordered={true}
                    // loading={loading ? {
                    //     spinning: loading,
                    //     indicator: <Spin tip="Loading..." size="large" />,
                    // } : false}
                    locale={{
                        emptyText: loading ? <Skeleton active={true} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }}
                    scroll={{ x: true }}
                    sticky={{ offsetHeader: window.screen.width > 992 ? 84 : 57 }}
                    onChange={ScrollToTopPagination}
                    pagination={false}
                    {...options}
                />
            </Spin>
        </>
    )
}
export default TableAnnouncer