import useSWR from 'swr'
import ServiceAPI from '~services/services'
// import { DateRangeType } from '~types/IDateRange'
import {
	UserRole
} from '~types/users'
import { QueryParams } from '~types/queryParams'

export const useFetchUsers = (username: string, role: UserRole, params?: QueryParams) => {
	const { data, mutate, error } = useSWR([`/members/${username}/${role}?limit=${params?.limit}&page=${params?.page}`, params], () =>
		ServiceAPI.getUserChildren(username, role, params)
	);

	return {
		data,
		isLoading: !error && !data,
		isError: error,

		mutate,
	}
}

export default useFetchUsers
