import useSWR from 'swr'
import ServiceAPI from '~services/services'
// import { DateRangeType } from '~types/IDateRange'
import { QueryParams } from '~types/queryParams'

export const useFetchNotifications = (params: QueryParams) => {
    const { data, mutate, error } = useSWR([`/notification?limit=${params?.limit}&page=${params?.page}`, params], () => ServiceAPI.getNotifications(params));

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchNotifications
