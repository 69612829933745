import React, { useState, useEffect, useCallback } from 'react'
// import styled from 'styled-components'
import MsnSlotLogo from '~assets/img/msnslot-logo.png'
// import logoDark from '~assets/img/msnslot-dark.png'
// import langTH from '~assets/img/flags/language/th.svg'
// import langEN from '~assets/img/flags/language/en.svg'
import { useDispatch } from 'react-redux'
import { useNavigate, Navigate } from 'react-router-dom'
import { AnyAction } from 'redux'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { toast } from 'react-toastify'
import { requestAuthInit } from '~store/auth/auth.actions'
import { requestProviderInit } from '~store/provider/provider.actions'
import { LoginParam } from '~types/auth'
import { ThunkDispatch } from '~types/store'
import AuthService from '~services/auth'
import PasswordInput from '~components/Elements/PasswordInput'
import { menuItems } from '~utils/RouteConfig'
import { Button } from 'antd'
// Dropdown, Menu, 
const Login = () => {
    const navigate = useNavigate();
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [otp, setOtp] = useState<string>('');
    const [show2FA, setShow2FA] = useState<boolean>(false);

    const { executeRecaptcha } = useGoogleReCaptcha();
    const [tokenGoogle, setTokenGoogle] = useState<string>("")
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (tokenGoogle) {
            // toast.error("Execute recaptcha not yet available, Please reload page.")
            // return;
            localStorage.setItem("googleRecaptcha", tokenGoogle)
        }
        try {
            await AuthService.login({
                username: username,
                password: password,
                otp: otp,
            } as LoginParam)
            dispatch(requestAuthInit(true))
            dispatch(requestProviderInit(true))
            navigate('/dashboard')
        } catch (error: any) {
            toast.error(error ? error.message as string : "ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ในขณะนี้")
            if (error.code === "incorrect_2fa") {
                setShow2FA(true)
            }
        }
    }

    const handleReCaptchaVerify = useCallback(async () => {
        setTokenGoogle("")
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('Login');
        setTokenGoogle(token)
    }, [executeRecaptcha]);

    useEffect(() => {
        handleReCaptchaVerify();
        return () => {
            setTokenGoogle("")
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const token = localStorage.getItem('x-auth-token')
    if (token) {
        return <Navigate to={menuItems[0]?.path} replace />
    }
    return (
        <div className="auth-page">
            <img className="logo" src={MsnSlotLogo} alt="logo msnslot" />
            <div className="auth-area">
                <div className="auth-wrapper">
                    {/* <Dropdown
                        overlay={(
                            <Menu>
                                <Menu.Item key="0">
                                    <img src={langTH} alt="thai" />
                                    TH
                                </Menu.Item>
                                <Menu.Item key="1">
                                    <img src={langEN} alt="english" />
                                    EN
                                </Menu.Item>
                            </Menu>
                        )}
                        placement="bottomRight"
                        trigger={['click']}>
                        <a className="ant-dropdown-link"
                            onClick={e => e.preventDefault()}>
                            <img src={langTH} alt="thai" />
                            TH
                            <i className="fa-regular fa-angle-down"></i>
                        </a>
                    </Dropdown> */}
                    <form onSubmit={handleSubmit}>
                        <div className="h-title">Sign in</div>
                        <div className="form-group">
                            <i className="fa-regular fa-circle-user"></i>
                            <div className="form-floating">
                                <input type="text" className="form-control" id="floatingUsername" placeholder="Username" onChange={e => setUsername(e.target.value)} value={username} required />
                                <label>Username</label>
                            </div>
                        </div>
                        <div className="form-group">
                            <i className="fa-regular fa-lock-keyhole"></i>
                            <PasswordInput
                                password={password}
                                handleChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        {show2FA &&
                            <div className="form-group">
                                <i className="fa-regular fa-circle-user"></i>
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="floatingOTP" placeholder="OTP" onChange={e => setOtp(e.target.value)} value={otp} required />
                                    <label>OTP</label>
                                </div>
                            </div>
                        }
                        <Button type="primary" htmlType="submit" block>LOGIN</Button>
                        {/* <p className="mt-4 mb-0 text-center text-muted"><small>Copyright © 2022 <a href="/">MSNSLOT</a>. All rights reserved.</small></p> */}
                    </form>
                </div>
            </div>
        </div>
    )
}

const LoginWithReCaptcha = () => {
    const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY : "6LfRRGUgAAAAAAzEuFxo1hNZ3ZemOamWWGpi1kSR"
    return (
        <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY} language="en">
            <Login />
        </GoogleReCaptchaProvider>
    );
};

export default LoginWithReCaptcha

// const Wrapper = styled.div`
// 	// height: 100vh;
// 	// display: flex;
// 	// align-items: center;
// 	// justify-content: center;
// 	// background: #F8F9FD;
// `;

// const SignIn = styled.main`
// 	// width: 100%;
// 	// max-width: 400px;
// 	// form {
// 	// 	padding: 24px;
// 	// 	background: #fff;
// 	// 	box-shadow: 0 12px 24px 0 rgba(128,144,208,0.08);
// 	// 	border-radius: 16px;
// 	// }
// `;