import React, { FC } from 'react'
import { Table, Spin, Skeleton, Empty } from 'antd'
// import { LoadingOutlined } from '@ant-design/icons'
import { nanoid } from 'nanoid'
import ScrollToTopPagination from '~utils/ScrollToTopPagination'

interface TableProps {
    className?: string
    columns: any
    dataSource: any
    loading?: boolean
    title?: string
    footer?: string
    currentPage?: number
    totalCount?: number
    bordered?: boolean
    onChangePagination?: (e: any) => void
    rowClassName?: (e: any) => void
    expandable?: any
}

const TableComponent: FC<TableProps> = ({
    columns,
    dataSource,
    loading,
    title,
    footer,
    currentPage,
    totalCount = 0,
    onChangePagination,
    rowClassName,
    bordered = true,
    expandable,
    className = ""
}) => {

    let options: any = {}
    if (title) {
        options['title'] = () => title
    }
    if (footer) {
        options['footer'] = () => footer
    }
    if (expandable) {
        options['expandable'] = () => expandable
    }

    const paginationOptions: any = {
        size: "default",
        defaultPageSize: 25,
        showSizeChanger: true,
        pageSizeOptions: ['25', '50', '100', '200'],
        position: ['topRight', 'bottomRight'],
        locale: { items_per_page: " / Page" },
        total: totalCount,
        current: currentPage || 1,
        showLessItems: (window.screen.width < 768 ? true : false),
        // showTotal: (total: number) => `Total ${total} items`,
        onChange: (page: number, pageSize: number) => {
            onChangePagination && onChangePagination({ page: page, pageSize: pageSize })
        }
    }

    return (
        <Spin spinning={loading} tip="Loading..." size="large" >
            <Table
                className={className}
                size={'small'}
                columns={columns as any}
                dataSource={dataSource}
                rowKey={() => nanoid()}
                // loading={loading ? {
                //     spinning: loading,
                //     indicator: <Spin tip="Loading..." size="large" />,
                // } : false}
                locale={{
                    emptyText: loading ? <Skeleton active={true} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }}
                scroll={{ x: true, y: true }}
                sticky={{ offsetHeader: window.screen.width > 992 ? 84 : 57 }}
                onChange={ScrollToTopPagination}
                pagination={paginationOptions}
                rowClassName={rowClassName && rowClassName}
                bordered={bordered}
                {...options}
            />
        </Spin>
    )
}
export default TableComponent