import { ISidebar } from '~types/ISidebar'
// import i18n from "~utils/i18n"

const onLoadMenuSidebar = () => {
    const menuSidebar: Array<ISidebar> = [
        {
            id: 'dashboard',
            permission_key: '',
            title: "Dashboard",
            path: "/dashboard",
            icon: "fa-duotone fa-house-chimney",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'management',
            permission_key: '',
            title: "Management",
            path: "#",
            icon: "fa-duotone fa-users",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'management_agents',
            permission_key: 'USER-MANAGEMENT',
            title: "Agents",
            path: "/agents",
            icon: "",
            isShow: true,
            parent_id: 'management'
        },
        {
            id: 'management_members',
            permission_key: 'USER-MANAGEMENT',
            title: "Members",
            path: "/members",
            icon: "",
            isShow: true,
            parent_id: 'management'
        },
        {
            id: 'management_sub_accounts',
            permission_key: 'SUB-USER-MANAGEMENT',
            title: "Sub Accounts",
            path: "/sub-accounts",
            icon: "",
            isShow: true,
            parent_id: 'management'
        },
        {
            id: 'reports',
            permission_key: 'REPORT',
            title: "Reports",
            path: "#",
            icon: "fa-duotone fa-file-chart-pie",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'reports_winloss',
            permission_key: 'REPORT',
            title: "Win/Loss",
            path: "/winloss",
            icon: "",
            isShow: true,
            parent_id: 'reports'
        },
        {
            id: 'feedback',
            permission_key: 'FEEDBACK',
            title: "Feedbacks",
            path: "/feedbacks",
            icon: "fa-duotone fa-comment-dots",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'Notification',
            permission_key: 'NOTIFICATION-MANAGEMENT',
            title: "Notification",
            path: "/notification",
            icon: "fa-duotone fa-bells",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'Announcement',
            permission_key: 'ANNOUNCEMENT-MANAGEMENT',
            title: "Announcement",
            path: "/announcement",
            icon: "fa-duotone fa-megaphone",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'system_logs',
            permission_key: '',
            title: "System logs",
            path: "#",
            icon: "fa-duotone fa-history",
            isShow: true,
            parent_id: '0'
        },
        {
            id: 'system_logs_access_logs',
            permission_key: 'ACCESS-LOGS',
            title: "Access Logs",
            path: "/access-logs",
            icon: "",
            isShow: true,
            parent_id: 'system_logs'
        },
        {
            id: 'system_logs_payment_logs',
            permission_key: 'PAYMENT-LOGS',
            title: "Payment Logs",
            path: "/payment-logs",
            icon: "",
            isShow: true,
            parent_id: 'system_logs'
        },
        // {
        //     id: 6,
        //     permission_key: '',
        //     features_key: '',
        //     title: "Payment",
        //     path: "#",
        //     icon: "fa-duotone fa-money-bill-wave",
        //     isShow: true,
        //     parent_id: 0
        // },
        // {
        //     id: 7,
        //     permission_key: '',
        //     features_key: '',
        //     title: "Deposit & Withdraw",
        //     path: "/payment-transfer",
        //     icon: "",
        //     isShow: true,
        //     parent_id: 6
        // },
        // {
        //     id: 8,
        //     permission_key: '',
        //     features_key: '',
        //     title: "Statement",
        //     path: "/payment-statement",
        //     icon: "",
        //     isShow: true,
        //     parent_id: 6
        // },
    ]

    const listMenuSidebar: any = menuSidebar?.reduce((item: any, menu: any) => {
        if (menu.parent_id === '0') {
            item[menu.id] = menu
        } else {
            if (typeof item[menu.parent_id]['submenu'] === 'undefined') {
                item[menu.parent_id]['submenu'] = {}
            }
            item[menu.parent_id]['submenu'][menu.id] = menu
        }
        return item;
    }, {})

    return listMenuSidebar
}


// // i18next seems ready -> initial fill translations
// // if (i18n.isInitialized) {
// //     onLoadMenuSidebar();
// // }

// //   // reset translations to new values on language change
// i18n.on('languageChanged', function (lng) {
//     onLoadMenuSidebar();
// });

export default onLoadMenuSidebar


