import React, { FC, useEffect, useState } from 'react'
import {
    Drawer,
    Space,
    Divider,
    Skeleton,
    Descriptions,
    Tabs,
    Tag,
    Typography,
    Empty
} from 'antd'
import { useNavigate } from "react-router-dom"
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import to from 'await-to-js'
import moment from 'moment'
import ServiceAPI from '~services/services'
import NumberWithCommas from '~utils/NumberWithCommas'
import { getGamesType } from '~utils/games'
import HistoryEdit from '~components/Users/HistoryEdit'
import { ApplicationProviderState, ApplicationState } from '~store/index'
import Button from 'antd-button-color'
// import { CopyOutlined } from '@ant-design/icons'

interface ViewProps {
    username: string
    close: () => void
    isOpen: boolean
}

const ViewAgent: FC<ViewProps> = ({ close, isOpen, username }) => {
    const authState = useSelector((state: ApplicationState) => state.auth)
    const permissions = authState.data.permissions
    const { providers: providerState } = useSelector((state: ApplicationProviderState) => state.providers)

    let navigate = useNavigate();
    const [dataSource, setDataSource] = useState<any>([])
    const [dataProducts, setDataProducts] = useState<any>([])
    const [dataLoading, setDataLoading] = useState<boolean>(false)

    const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);
    const toggleHistory = () => setIsHistoryOpen(!isHistoryOpen)

    const handleFetchData = async (username: string) => {
        if (username) {
            setDataLoading(true)
            const [err, res] = await to(ServiceAPI.getUser(username))
            if (err) {
                toast.error(err?.message as string)
                setDataLoading(false)
            } else {
                setDataSource(res.data)
                setDataLoading(false)
            }
        }
    }

    // const listProducts = (gameType: string) => {
    //     if (dataProducts.length > 0) {
    //         const products = Object.keys(dataProducts).map((key: string, index: any) => {
    //             if (!dataProducts[key].enabled) {
    //                 return []
    //             }
    //             const labelCheckbox = dataProducts[key]
    //             const res = getGames(labelCheckbox.key)
    //             res.enabled = dataProducts[key].enabled
    //             res.ownPT = dataProducts[key].ownPT
    //             return res
    //         })
    //         return products.filter((product: any) => product.type === gameType)
    //     }
    // }

    const TabTypeProducts = Object.keys(getGamesType).map((item: string, index: any) => {
        // const productList: any = listProducts(item.toLocaleUpperCase())
        const productList: any = dataProducts.filter((product: any) => product.type === item.toLocaleUpperCase())
        const productType = (Object.values(getGamesType)[index]).toLocaleUpperCase()

        const productElement = productList && productList.length > 0 ? productList.map((product: any) => {
            if (!product.enabled) {
                return ""
            }
            return (
                <Descriptions.Item label={product.name} key={product.key}>
                    {product.ownPT}%
                </Descriptions.Item>
            )
        }) : "";
        return {
            label: productType,
            key: index,
            children: (
                (productList && productList.length > 0) ? (
                    <Descriptions column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }} bordered>
                        <Descriptions.Item label={<span style={{ fontWeight: "bold" }}>Game Name</span>} key={0}>
                            <span style={{ fontWeight: "bold" }}>Percentage</span>
                        </Descriptions.Item>
                        {productElement}
                    </Descriptions>
                ) : <Empty key={`Empty ${productType}`} />
            ),
            disabled: productList && productList.length > 0 ? false : true
        }
    })


    // const handleCopy = (values: string) => {
    //     navigator.clipboard.writeText(values)
    //     message.success('Copy successfully');
    // }

    useEffect(() => {
        if (isOpen && username) {
            setDataSource([])
            handleFetchData(username)
        }
    }, [isOpen, username])

    useEffect(() => {
        setDataProducts([])
        if (isOpen && dataSource !== undefined && Object.keys(dataSource).length > 0) {
            const productIn = Object.keys(dataSource.products).map((key: string) => {
                // const game = getGames(key)
                const provider = providerState.data[key]
                return {
                    "key": key,
                    "name": provider.name,
                    "type": provider.type,
                    "ownPT": dataSource.products[key].ownPT,
                    "enabled": dataSource.products[key].enabled,
                }
            })
            setDataProducts(productIn);
        }
    }, [isOpen, dataSource, providerState])

    return (
        <Drawer
            title={`View Agent`}
            onClose={close}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .6}
            extra={
                <Space>
                    {permissions && permissions["USER-MANAGEMENT"] === "EDIT" && (
                        <Button
                            type="warning"
                            className="btn btn-warning btn-sm text-white me-2"
                            onClick={() => { navigate(`edit/${username}`) }}>
                            <i className="fa-solid fa-edit me-1"></i>
                            Edit</Button>
                    )}
                    <Button
                        type="info"
                        key={`history`}
                        className="btn btn-info btn-sm text-white me-2"
                        onClick={toggleHistory}>
                        <i className="fa-solid fa-history me-1"></i>
                        History</Button>
                </Space>
            }>
            {dataLoading ? (
                <Skeleton active />
            ) : (
                <>
                    <Tabs
                        defaultActiveKey="0"
                        centered
                        items={[
                            {
                                label: "Agent",
                                key: '0',
                                children: (
                                    <>
                                        <Descriptions title={"Agent Information"} column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                                            <Descriptions.Item label="Username">{dataSource.username}</Descriptions.Item>
                                            <Descriptions.Item label="Name">{dataSource.name}</Descriptions.Item>
                                            <Descriptions.Item label="Role">{dataSource.role}</Descriptions.Item>
                                            <Descriptions.Item label="Credit">{NumberWithCommas(dataSource.balance, 2)}</Descriptions.Item>
                                            <Descriptions.Item label="Currency">{dataSource.currency}</Descriptions.Item>
                                            <Descriptions.Item label="MSN Prefix">{dataSource.msnPrefix ? dataSource.msnPrefix : "-"}</Descriptions.Item>
                                        </Descriptions>
                                        {/* {dataSource.apiKey && (
                                            <>
                                                <Divider />
                                                <Descriptions title={"API Key"} column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                                                    <Space>
                                                        <Descriptions.Item label="APIKey">
                                                            {dataSource.apiKey ? dataSource.apiKey : "-"}
                                                            <Tooltip title="Copy APIKey">
                                                                <Button icon={<CopyOutlined />} className="ms-2 mt2" disabled={dataSource?.apiKey ? false : true} onClick={() => handleCopy(dataSource.apiKey)} />
                                                            </Tooltip>
                                                        </Descriptions.Item>

                                                    </Space>
                                                </Descriptions>
                                            </>
                                        )} */}
                                        <Divider />
                                        <Descriptions title={"Contact Information"} column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                                            <Descriptions.Item label="Person In Charge">{dataSource.person || "-"}</Descriptions.Item>
                                            <Descriptions.Item label="Email">{dataSource.email || "-"}</Descriptions.Item>
                                            <Descriptions.Item label="Phone Number">{dataSource.phone || "-"}</Descriptions.Item>
                                            <Descriptions.Item label="Other Contact">{dataSource.other || "-"}</Descriptions.Item>
                                        </Descriptions>
                                        <Divider />
                                        <Descriptions title="System Information" column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                                            <Descriptions.Item label="Lock">
                                                {dataSource.lock
                                                    ? <Tag color="red">Lock</Tag>
                                                    : <Tag color="green">Unlock</Tag>}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Suspended">
                                                {dataSource.suspended
                                                    ? <Tag color="red">Yes</Tag>
                                                    : <Tag color="green">No</Tag>}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="CreatedAt">{moment(dataSource.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Descriptions.Item>
                                            <Descriptions.Item label="UpdatedAt">{moment(dataSource.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</Descriptions.Item>
                                            <Descriptions.Item label="Last login">
                                                {dataSource.loginAt !== "1970-01-01 07:00:00"
                                                    ? (<>
                                                        {moment(dataSource.loginAt).format('DD/MM/YYYY HH:mm:ss')}
                                                        <div className="msn-badge badge-light ms-2">{moment(dataSource.loginAt).fromNow()}</div>
                                                    </>) : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Login IP">{dataSource.loginIP ? dataSource.loginIP : "-"}</Descriptions.Item>
                                        </Descriptions>
                                    </>
                                )
                            },
                            {
                                label: "Product",
                                key: '1',
                                children: (
                                    <>
                                        <Typography.Title level={5}>Products Information</Typography.Title>
                                        <Tabs defaultActiveKey="0" centered items={TabTypeProducts} />
                                    </>
                                )
                            }
                        ]} />
                    {dataSource.username && isHistoryOpen && (
                        <HistoryEdit
                            isOpen={isHistoryOpen}
                            close={() => toggleHistory()}
                            username={dataSource.username}
                            role={dataSource.role}
                        />
                    )}
                </>
            )
            }
        </Drawer >
    )
}

export default ViewAgent