import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '~store/index'
import moment from 'moment'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import TitleBar from '~components/Elements/TitleBar'
import FormattedMessage from '~components/Elements/FormattedMessage'
import DatePickerRangeShowtime from '~components/Elements/DatePickerRangeShowtime'
import DateFilterShowtime from '~components/Elements/DateFilterShowtime'
import Amount from '~components/Elements/Amount'
// import BadgeComponent from '~components/Elements/BadgeComponent'
// import Search from '~components/Elements/Search'
import useDateRangeShowtime from "~hooks/useDateRangeShowtime"
import useDatePagination from "~hooks/useDatePagination"
import useFetchReportWinLossMember from "~hooks/useFetchReportWinLossMember"
import ScrollToTopPagination from '~utils/ScrollToTopPagination'
import { Table, Skeleton, Empty, Typography, Breadcrumb } from 'antd'
// import { games } from '~utils/games'
// import { LoadingOutlined } from '@ant-design/icons'

const WinLossMember = () => {
    const { username } = useParams();
    let navigate = useNavigate();

    const authState = useSelector((state: ApplicationState) => state.auth)
    const authStateUser = authState.data.user
    const usernameSearch = username ? username : authStateUser?.username
    const params: any = useLocation();

    const routes = [
        {
            path: '',
            breadcrumbName: 'Reports',
        },
        {
            path: '/winloss',
            breadcrumbName: 'Win/Loss Member',
        },
    ];

    const [dataSource, setDataSource] = useState<any>([])
    const { pagination, setPagination } = useDatePagination()
    const { dateRange, setDateRange } = useDateRangeShowtime()
    const { data, mutate, isLoading } = useFetchReportWinLossMember({
        username: usernameSearch,
        startDate: dateRange.start,
        endDate: dateRange.end,
        page: pagination.page,
        limit: pagination.pageSize
    })

    // const [filterText, setFilterText] = useState('')
    // const [searchAlldata, setSearchAllData] = useState<string>("")

    const columns = [
        {
            title: 'Information',
            dataIndex: 'Information',
            width: 3,
            render: (text: any, record: any) => (
                <>
                    <span className="text-span"><Typography.Text strong>Username : {record.username}</Typography.Text></span>
                    <span className="text-span">ID : {record.id}</span>
                    <span className="text-span">Date/Time : {moment(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}</span>
                </>
            )
        },
        {
            title: 'Event & Details',
            dataIndex: 'event',
            width: 3,
            render: (text: any, record: any) => (
                <>
                    <span className="text-span"><Typography.Text strong>Provider : {record.provider}</Typography.Text></span>
                    <span className="text-span"><Typography.Text strong>Game Code : {record.gameCode}</Typography.Text></span>
                    <span className="text-span"><Typography.Text strong>roundID : {record.roundID}</Typography.Text></span>
                </>
            )
        },
        {
            title: 'Bet Amount',
            dataIndex: 'betAmount',
            align: 'right',
            render: (text: any, record: any) => (
                <Amount amount={record.betAmount} />
            )
        },
        {
            title: 'Win Amount',
            dataIndex: 'winAmount',
            align: 'right',
            render: (text: any, record: any) => (
                <Amount amount={record.winAmount} />
            )
        },
        {
            title: 'Win/Loss & Comm & Total',
            children: [
                {
                    title: 'Member',
                    dataIndex: 'member',
                    align: 'right',
                    render: (text: any, record: any) => (
                        <span className="ant-cus-vertical text-span">
                            <Amount amount={record.member.winloss} />
                            <Amount amount={record.member.commission} />
                            <Amount amount={record.member.winloss + record.member.commission} />
                        </span>
                    )
                },
                {
                    title: 'Agent',
                    dataIndex: 'agent',
                    align: 'right',
                    render: (text: any, record: any) => (
                        <span className="ant-cus-vertical text-span">
                            <Amount amount={record.agent.winloss} />
                            <Amount amount={record.agent.commission} />
                            <Amount amount={record.agent.winloss + record.agent.commission} />
                        </span>
                    )
                },
                {
                    title: 'Company',
                    dataIndex: 'company',
                    align: 'right',
                    render: (text: any, record: any) => (
                        <span className="ant-cus-vertical text-span">
                            <Amount amount={record.company.winloss} />
                            <Amount amount={record.company.commission} />
                            <Amount amount={record.company.winloss + record.company.commission} />
                        </span>
                    )
                },
            ],
        },
    ]

    // const expandedRowRender = (data: any) => {
    //     const columns = [
    //         { title: 'ID', dataIndex: 'id', key: 'id', width: '140' },
    //         {
    //             title: 'Type',
    //             dataIndex: 'type',
    //             width: '140',
    //             align: 'center',
    //             render: (text: any, record: any) => (
    //                 <>
    //                     {record.type}
    //                 </>
    //             ),
    //         },
    //         {
    //             title: 'Bet Amount',
    //             dataIndex: 'betAmount',
    //             width: '200',
    //             align: 'center',
    //             render: (text: any, record: any) => (
    //                 <Amount amount={record.betAmount} />
    //             ),
    //         },
    //         {
    //             title: 'Win Amount',
    //             dataIndex: 'winAmount',
    //             width: '200',
    //             align: 'center',
    //             render: (text: any, record: any) => (
    //                 <Amount amount={record.winAmount} />
    //             ),
    //         },
    //         {
    //             title: 'Status',
    //             dataIndex: 'status',
    //             width: '200',
    //             align: 'center',
    //             render: (text: any, record: any) => (
    //                 <>
    //                     {record.status}
    //                 </>
    //             ),
    //         },
    //         {
    //             title: 'Date/Time',
    //             dataIndex: 'createdAt',
    //             width: '140',
    //             render: (text: any, record: any) => (
    //                 <div className="row-datetime">
    //                     <div><span>{moment(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}</span></div>
    //                 </div>
    //             ),
    //         },
    //     ];

    //     return <TableWithoutPagination
    //         columns={columns}
    //         dataSource={data || []}
    //         loading={false}
    //         bordered={true}
    //     />;
    // };


    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data.totalCount > 0) {
            setDataSource(data.data)
        }
        return () => {
            setDataSource([])
        }
    }, [data, isLoading])

    useEffect(() => {
        if (params && params.state) {
            setDateRange({
                start: moment(params?.state.startDate).format('YYYY-MM-DD HH:mm:ss'),
                end: moment(params?.state.endDate).format('YYYY-MM-DD HH:mm:ss')
            })
        }

        return () => {
            setDateRange({
                start: moment().format('YYYY-MM-DD 00:00:00'),
                end: moment().format('YYYY-MM-DD 23:59:59'),
            })
        }
    }, [params, setDateRange])

    useMemo(() => {
        mutate()
    }, [setDateRange, setPagination])// eslint-disable-line

    // const handleSearch = () => {
    //     // setSearchAllData(filterText)
    //     setPagination({ page: 1, pageSize: pagination.pageSize })
    //     mutate()
    // }

    // const handleSelectProvider = (value: string[]) => {
    //     console.log(`selected ${value}`);
    // };

    // useMemo(() => {
    //     if (searchParams.get("search")) {
    //         setFilterText(searchParams.get("search") || "")
    //         setSearchMember(searchParams.get("search") || "")
    //         mutate()
    //     }
    // }, [searchParams, setSearch])// eslint-disable-line

    return (
        <>
            <TitleBar title={<FormattedMessage id={'main.winloss_member'} defaultMessage={`Win/Loss Member`} />} subTitle={""} routes={routes} />
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRangeShowtime onChangeDate={setDateRange} onDateActive={dateRange} funClear={true} />
                <DateFilterShowtime onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            {/* <div className="d-flex justify-content-start mb-4">
                <Search onChangeSearch={setFilterText} onHandleSearch={handleSearch} filterText={filterText} />
                <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">Search</button>
            </div> */}
            <Breadcrumb separator="" className="mb-3" style={{ fontSize: 16, cursor: "pointer" }}>
                {data && data.parents.map((item: any, index: any) => (
                    <Fragment key={`fragment-${item}-${index}`}>
                        {index === 0 ?
                            <Breadcrumb.Item key={`item-${item}-${index}`}
                                onClick={() => navigate(`/winloss`, {
                                    state: {
                                        startDate: dateRange.start,
                                        endDate: dateRange.end
                                    },
                                })} >{item}</Breadcrumb.Item>
                            :
                            <Breadcrumb.Item key={`item-${item}-${index}`}
                                onClick={() => navigate(`/winloss/${item}`, {
                                    state: {
                                        startDate: dateRange.start,
                                        endDate: dateRange.end
                                    },
                                })} >{item}</Breadcrumb.Item>
                        }
                        <Breadcrumb.Separator key={`separator-${item}`} />
                    </Fragment>
                ))}
                <Breadcrumb.Item key={`item${usernameSearch}`}>{usernameSearch}</Breadcrumb.Item>
            </Breadcrumb>
            {/* <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <Space>
                    Select Provider:
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%', minWidth: '200px' }}
                        placeholder="Default All Provider"
                        onChange={handleSelectProvider}>
                        {Object.keys(games).map((keyGame: any) => (
                            <Select.Option key={keyGame} value={keyGame}>{games[keyGame].name}</Select.Option>
                        ))}
                    </Select>
                </Space>
            </div> */}
            <div className="box-white ant-box-table">
                <Table
                    className="table-winloss"
                    size={'small'}
                    tableLayout={'fixed'}
                    rowKey={(record) => record.id}
                    columns={columns as any}
                    dataSource={dataSource || []}
                    locale={{
                        emptyText: isLoading ? <Skeleton active={true} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }}
                    scroll={{ x: true }}
                    sticky={{ offsetHeader: window.screen.width > 992 ? 84 : 57 }}
                    pagination={{
                        size: "default",
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ['25', '50', '100', '200'],
                        position: ['topRight', 'bottomRight'],
                        locale: { items_per_page: " / Page" },
                        total: dataSource?.totalCount || 0,
                        current: pagination.page || 1,
                        showLessItems: (window.screen.width < 768 ? true : false),
                        // showTotal: (total: number) => `Total ${total} items`,
                        onChange: (page: number, pageSize: number) => {
                            setPagination({ page: page, pageSize: pageSize })
                        }
                    }}
                    onChange={ScrollToTopPagination}
                    bordered
                // expandable={{
                //     expandedRowRender: record => expandedRowRender(record.subTxns),
                //     expandIcon: ({ expanded, onExpand, record }) =>
                //         expanded ? (
                //             <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                //         ) : (
                //             <PlusCircleTwoTone onClick={e => onExpand(record, e)} />
                //         )
                // }}
                // expandRowByClick={true}
                />
            </div>
        </>
    );
};

export default WinLossMember