import React, { Fragment, useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import NumberWithCommas from '~utils/NumberWithCommas'
import TitleBar from '~components/Elements/TitleBar'
import FormattedMessage from '~components/Elements/FormattedMessage'
import DatePickerRange from '~components/Elements/DatePickerRange'
import DateFilter from '~components/Elements/DateFilter'
import Table from '~components/Elements/Table'
// import BadgeComponent from '~components/Elements/BadgeComponent'
import Search from '~components/Elements/Search'
import useDateRangeEmpty from "~hooks/useDateRangeEmpty"
import useDatePagination from "~hooks/useDatePagination"
import useFetchPaymentLogs from "~hooks/useFetchPaymentLogs"
import { Tag } from 'antd'

const AccessLogs = () => {
    const routes = [
        {
            path: '',
            breadcrumbName: 'Logs',
        },
        {
            path: '',
            breadcrumbName: 'Payment Logs',
        },
    ];

    const [filterText, setFilterText] = useState<string>("")
    const [search, setSearch] = useState<string>("")
    const [dataSource, setDataSource] = useState<any>([])
    const { pagination, setPagination } = useDatePagination()
    const { dateRange, setDateRange } = useDateRangeEmpty()
    const { data, mutate, isLoading } = useFetchPaymentLogs({
        page: pagination.page,
        limit: pagination.pageSize,
        startDate: dateRange.start,
        endDate: dateRange.end,
        search: search
    }, 10000)


    const columns = [
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            width: '140px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'Sender',
            dataIndex: 'sender',
            render: (text: any, record: any) => (
                <div>{record.sender}</div>
            )
        },
        {
            title: 'Receiver',
            dataIndex: 'Receiver',
            render: (text: any, record: any) => (
                <div>{record.receiver}</div>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (text: any, record: any) => (
                <Tag color={record.action === "DEPOSIT" ? "green" : "red"}>{record.action}</Tag>
            )
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    {NumberWithCommas(record.amount, 2)}
                </div>
            )
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            align: 'center',
            render: (text: any, record: any) => (
                <div>{record.currency}</div>
            )
        },
        {
            title: 'Operated by',
            dataIndex: 'createdBy',
            render: (text: any, record: any) => (
                <div>{record.createdBy}</div>
            )
        },
    ]


    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data.totalCount > 0) {
            setDataSource(data.data)
        }
        return () => {
            setDataSource([])
        }
    }, [data, isLoading, setDataSource])

    useMemo(() => {
        mutate()
    }, [setDateRange, setPagination])// eslint-disable-line

    const handleSearch = () => {
        setSearch(filterText)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        mutate()
    }
    // useMemo(() => {
    //     if (searchParams.get("search")) {
    //         setFilterText(searchParams.get("search") || "")
    //         setSearchMember(searchParams.get("search") || "")
    //         mutate()
    //     }
    // }, [searchParams, setSearch])// eslint-disable-line

    return (
        <>
            <TitleBar title={<FormattedMessage id={'main.paymentLogs'} defaultMessage={'Payment Logs'} />} subTitle={`${NumberWithCommas(data?.totalCount || 0)} items`} routes={routes} />
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} funClear={true} />
                <DateFilter onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            <div className="d-flex justify-content-start mb-4">
                <Search onChangeSearch={setFilterText} onHandleSearch={handleSearch} filterText={filterText} />
                <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">Search</button>
            </div>
            <div className="box-white ant-box-table">
                <Table
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    totalCount={data?.totalCount || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>

        </>
    );
};

export default AccessLogs