import React, { Fragment, useState, useEffect, useMemo } from 'react'
import moment from 'moment'
// import NumberWithCommas from '~utils/NumberWithCommas'
import TitleBar from '~components/Elements/TitleBar'
import FormattedMessage from '~components/Elements/FormattedMessage'
import DatePickerRange from '~components/Elements/DatePickerRange'
import DateFilter from '~components/Elements/DateFilter'
import Table from '~components/Elements/Table'
// import BadgeComponent from '~components/Elements/BadgeComponent'
import Search from '~components/Elements/Search'
import useDateRangeEmpty from "~hooks/useDateRangeEmpty"
import useDatePagination from "~hooks/useDatePagination"
import useFetchFeedbacks from "~hooks/useFetchFeedbacks"
import NumberWithCommas from '~utils/NumberWithCommas'
import { Select } from 'antd'

const Feedbacks = () => {
    const routes = [
        {
            path: '',
            breadcrumbName: 'Feedbacks',
        },
    ];

    const [dataSource, setDataSource] = useState<any>([])
    const { pagination, setPagination } = useDatePagination()
    const { dateRange, setDateRange } = useDateRangeEmpty()
    const [filterText, setFilterText] = useState('')
    const [search, setSearch] = useState<string>("")
    const [typeSearch, setTypeSearch] = useState<string>("")

    const { data, mutate, isLoading } = useFetchFeedbacks({
        page: pagination.page,
        limit: pagination.pageSize,
        startDate: dateRange.start,
        endDate: dateRange.end,
        search: search,
        type: typeSearch,
    }, 10000)

    const columns = [
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            width: '180px',
            align: 'center',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'Parent User',
            dataIndex: 'parents',
            width: '180px',
            render: (text: any, record: any) => {
                const parent = record.parents[Object.values(record.parents).length - 1]
                return (
                    <div>{parent}</div>
                )
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            align: 'center',
            width: '140px',
            render: (text: any, record: any) => (
                <div>{(record.type).toUpperCase()}</div>
            )
        },
        {
            title: 'Username',
            dataIndex: 'username',
            render: (text: any, record: any) => (
                <div>{record.username}</div>
            )
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone',
            render: (text: any, record: any) => (
                <div>{record.phone}</div>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            render: (text: any, record: any) => (
                <div>{record.description}</div>
            )
        },
        {
            title: 'IP Address',
            dataIndex: 'ip',
            align: 'center',
            render: (text: any, record: any) => (
                <div>{record.ip}</div>
            )
        }
    ]


    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data.totalCount > 0) {
            setDataSource(data.data)
        }
        return () => {
            setDataSource([])
        }
    }, [data, isLoading])

    useMemo(() => {
        mutate()
    }, [setDateRange, setPagination])// eslint-disable-line

    const handleSearch = () => {
        setSearch(filterText)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        mutate()
    }

    // useMemo(() => {
    //     if (searchParams.get("search")) {
    //         setFilterText(searchParams.get("search") || "")
    //         setSearchMember(searchParams.get("search") || "")
    //         mutate()
    //     }
    // }, [searchParams, setSearch])// eslint-disable-line


    return (
        <>
            <TitleBar title={<FormattedMessage id={'main.feedbacks'} defaultMessage={'Feedbacks'} />} subTitle={`${NumberWithCommas(data?.totalCount || 0)} items`} routes={routes} />
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} funClear={true} />
                <DateFilter onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <div className="d-flex justify-content-start mb-4">
                    <Search onChangeSearch={setFilterText} onHandleSearch={handleSearch} filterText={filterText} />
                    <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">Search</button>
                </div>
                <div className="d-flex justify-content-start mb-4">
                    <div className="ms-auto mt-3 mt-md-0 mt-xl-0">
                        <label className="me-2">Type : </label>
                        <Select
                            size="large"
                            dropdownMatchSelectWidth={false}
                            placement="bottomRight"
                            value={typeSearch}
                            onSelect={(value: any) => {
                                setTypeSearch(value)
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            <Select.Option key={"all"} value={""}>ALL</Select.Option>
                            <Select.Option key={"game"} value={"game"}>Game</Select.Option>
                            <Select.Option key={"agent"} value={"agent"}>Agent</Select.Option>
                            <Select.Option key={"deposit/withdraw"} value={"deposit/withdraw"}>Deposit/Withdraw</Select.Option>
                            <Select.Option key={"other"} value={"other"}>Other</Select.Option>
                        </Select>
                    </div>
                </div>
            </div>
            <div className="box-white ant-box-table">
                <Table
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    totalCount={data?.totalCount || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>
        </>
    );
};

export default Feedbacks