import React, { FC, useState, useEffect } from 'react'
import { Typography, Table, Drawer } from 'antd'
import to from 'await-to-js'
import moment from 'moment'
import { toast } from 'react-toastify'
import ServiceAPI from '~services/services'
import Loading from '~components/Elements/Loading'
import EmptyComponents from '~components/Elements/Empty'
import ViewHistoryEdit from '~components/Users/ViewHistoryEdit'

interface HistoryEditProps {
    username: string
    close: () => void
    isOpen: boolean
    role: string
}

const HistoryEdit: FC<HistoryEditProps> = ({ close, isOpen, username, role }) => {

    const [dataHistory, setDataHistory] = useState<any>([])
    const [dataViewHistory, setDataViewHistory] = useState<any>([])
    const [historyLoading, setHistoryLoading] = useState<boolean>(false)

    const [isViewHistoryOpen, setIsViewHistoryOpen] = useState<boolean>(false);
    const toggleModalViewHistory = () => setIsViewHistoryOpen(!isViewHistoryOpen)

    const handleFetchHistory = async (username: string) => {
        if (!username) {
            close()
        }
        setDataHistory([])
        setHistoryLoading(true)
        const [err, res] = await to(ServiceAPI.getHistoryEditUser(username))
        if (err) {
            toast.error(err?.message as string)
            setHistoryLoading(false)
        } else {
            setDataHistory(res.data)
            setHistoryLoading(false)
        }
    }

    const columnsHistory: any = [
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}</span></div>
                </div>
            )
        },
        {
            title: 'CreatedBy',
            dataIndex: 'createdBy',
            render: (text: any, record: any) => (
                <div className="row-member" style={{ minWidth: 70 }}>
                    {record.createdBy}
                </div>
            )
        },
    ]

    const historyTable = dataHistory && Object.keys(dataHistory).length > 0 ? (
        <Table
            columns={columnsHistory}
            dataSource={dataHistory || []}
            loading={historyLoading}
            pagination={false}
            size={'small'}
            rowKey={(record: any) => record.createdAt}
            bordered
            style={{ cursor: "pointer" }}
            onRow={(record, rowIndex) => {
                return {
                    onClick: e => {
                        setDataViewHistory(record);
                        toggleModalViewHistory();
                    },
                };
            }}
        />
    ) : <EmptyComponents />

    useEffect(() => {
        if (isOpen && username) {
            setDataHistory([])
            handleFetchHistory(username)
        }
    }, [isOpen, username]) // eslint-disable-line
    return (
        <Drawer
            title={`History edit`}
            onClose={close}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .6}>
            {historyLoading ? <Loading /> : (
                dataHistory && Object.keys(dataHistory).length > 0
                    ? (
                        <>
                            <Typography.Title level={4}>Username : {username}</Typography.Title>
                            {historyTable}
                        </>
                    )
                    : (<EmptyComponents />)
            )}
            <ViewHistoryEdit
                isOpen={isViewHistoryOpen}
                close={() => toggleModalViewHistory()}
                username={username}
                idHistory={dataViewHistory?.id}
                role={role}
            />
        </Drawer>
    )
}

export default HistoryEdit
