import useSWR from 'swr'
import ServiceAPI from '~services/services'
// import { DateRangeType } from '~types/IDateRange'
import { WinLossMemberParams } from '~types/reports'

export const useFetchReportWinLossMember = (params: WinLossMemberParams) => {
    const { data, mutate, error } = useSWR([`/winloss-member?username=${params.username}&startDate=${params.startDate}&endDate=${params.endDate}`, params],
        () => ServiceAPI.getReportWinLossMember(params)
    );

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchReportWinLossMember
