import React, { Fragment, useState, useEffect, useMemo } from 'react'
import moment from 'moment'
// import NumberWithCommas from '~utils/NumberWithCommas'
import { useSelector } from 'react-redux'
import { ApplicationState } from '~store/index'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import TitleBar from '~components/Elements/TitleBar'
import FormattedMessage from '~components/Elements/FormattedMessage'
import DatePickerRangeShowtime from '~components/Elements/DatePickerRangeShowtime'
import DateFilterShowtime from '~components/Elements/DateFilterShowtime'
import TableSummary from '~components/Elements/TableSummary'
import Amount from '~components/Elements/Amount'
// import BadgeComponent from '~components/Elements/BadgeComponent'
// import Search from '~components/Elements/Search'
import useDateRangeShowtime from "~hooks/useDateRangeShowtime"
import useDatePagination from "~hooks/useDatePagination"
import useFetchReportWinLoss from "~hooks/useFetchReportWinLoss"
// import BadgeComponent from '~components/Elements/BadgeComponent'
// import { Link } from 'react-router-dom'
import { Breadcrumb, Typography, Select, Space, Table } from 'antd'
import { games } from '~utils/games'


const WinLoss = () => {
    const { username } = useParams();
    let navigate = useNavigate();
    const params: any = useLocation();

    const authState = useSelector((state: ApplicationState) => state.auth)
    const authStateUser = authState.data.user
    const usernameSearch = username ? username : authStateUser?.username

    const routes = [
        {
            path: '',
            breadcrumbName: 'Reports',
        },
        {
            path: '',
            breadcrumbName: 'Win/Loss',
        },
    ];


    const [dataSource, setDataSource] = useState<any>([])
    const [dataSummary, setDataSummary] = useState<any>([])
    const { pagination, setPagination } = useDatePagination()
    const { dateRange, setDateRange } = useDateRangeShowtime()
    // const [filterText, setFilterText] = useState('')
    const [providerSearch, setProviderSearch] = useState<string>(Object.keys(games).map((item: any) => item.toUpperCase()).join(","))

    const { data, mutate, isLoading } = useFetchReportWinLoss({
        page: pagination.page,
        limit: pagination.pageSize,
        username: usernameSearch,
        startDate: dateRange.start,
        endDate: dateRange.end,
        providers: providerSearch
    })

    // const [searchAlldata, setSearchAllData] = useState<string>("")

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            width: "90px",
            render: (text: any, record: any) => (
                <>
                    {
                        record.role === "AGENT" ?
                            <div className="text-link" onClick={() => navigate(`/winloss/${record.username}`)} ><Typography.Text strong>{record.username}</Typography.Text></div>
                            :
                            <div className="text-link" onClick={() => navigate(`/winloss/${record.username}/member`, {
                                state: {
                                    startDate: dateRange.start,
                                    endDate: dateRange.end
                                },
                            })} ><Typography.Text strong>{record.username}</Typography.Text></div>
                    }
                </>
            )
        },
        {
            title: 'Level',
            dataIndex: 'role',
            align: 'center',
            width: "80px",
            render: (text: any, record: any) => (
                <>{record.role}</>
            )
        },
        {
            title: 'Turnover',
            dataIndex: 'turnover',
            align: 'center',
            width: "100px",
            render: (text: any, record: any) => (
                <Amount amount={record.turnover} />
            )
        },
        {
            title: 'Valid Turnover',
            dataIndex: 'validTurnover',
            align: 'center',
            width: "110px",
            render: (text: any, record: any) => (
                <Amount amount={record.validTurnover} />
            )
        },
        {
            title: 'Stake Count',
            dataIndex: 'stakeCount',
            align: 'center',
            width: "110px",
            render: (text: any, record: any) => (
                <Amount amount={record.stakeCount} decimal={false} />
            )
        },
        {
            title: 'Member',
            children: [
                {
                    title: 'Win/loss',
                    dataIndex: 'winloss',
                    align: 'center',
                    width: "100px",
                    render: (text: any, record: any) => (
                        <Amount amount={record.member.winloss} />
                    )
                },
                {
                    title: 'Commission',
                    dataIndex: 'commission',
                    align: 'center',
                    width: "110px",
                    render: (text: any, record: any) => (
                        <Amount amount={record.member.commission} />
                    )
                },
                {
                    title: 'Total',
                    dataIndex: 'total',
                    align: 'center',
                    width: "100px",
                    render: (text: any, record: any) => (
                        <Amount amount={record.member.winloss + record.member.commission} />
                    )
                },
            ],
        },
        {
            title: 'Agent',
            className: 'table-color-blue',
            children: [
                {
                    title: 'Win/loss',
                    dataIndex: 'winloss',
                    align: 'center',
                    width: "100px",
                    className: 'table-color-blue',
                    render: (text: any, record: any) => (
                        <Amount amount={record.agent.winloss} />
                    )
                },
                {
                    title: 'Commission',
                    dataIndex: 'commission',
                    align: 'center',
                    width: "110px",
                    className: 'table-color-blue',
                    render: (text: any, record: any) => (
                        <Amount amount={record.agent.commission} />
                    )
                },
                {
                    title: 'Total',
                    dataIndex: 'total',
                    align: 'center',
                    width: "100px",
                    className: 'table-color-blue',
                    render: (text: any, record: any) => (
                        <Amount amount={record.agent.winloss + record.agent.commission} />
                    )
                },
            ],
        },
        {
            title: 'Company',
            children: [
                {
                    title: 'Win/loss',
                    dataIndex: 'winloss',
                    align: 'center',
                    width: "100px",
                    render: (text: any, record: any) => (
                        <Amount amount={record.company.winloss} />
                    )
                },
                {
                    title: 'Commission',
                    dataIndex: 'commission',
                    align: 'center',
                    width: "110px",
                    render: (text: any, record: any) => (
                        <Amount amount={record.company.commission} />
                    )
                },
                {
                    title: 'Total',
                    dataIndex: 'total',
                    align: 'center',
                    width: "100px",
                    render: (text: any, record: any) => (
                        <Amount amount={record.company.winloss + record.company.commission} />
                    )
                },
            ],
        }
    ]


    useEffect(() => {
        setDataSource([])
        setDataSummary([])
        if (data !== undefined && data.totalCount > 0) {
            setDataSource(data.data)
            setDataSummary(data.summary)
        }
        return () => {
            setDataSource([])
            setDataSummary([])
        }
    }, [data, isLoading, usernameSearch])

    useEffect(() => {
        if (params && params.state) {
            setDateRange({
                start: moment(params?.state.startDate).format('YYYY-MM-DD HH:mm:ss'),
                end: moment(params?.state.endDate).format('YYYY-MM-DD HH:mm:ss')
            })
            mutate()
        }
    }, [params, setDateRange])// eslint-disable-line

    useMemo(() => {
        mutate()
    }, [setDateRange, setPagination, setProviderSearch])// eslint-disable-line

    // const handleSearch = () => {
    //     // setSearchAllData(filterText)
    //     setPagination({ page: 1, pageSize: pagination.pageSize })
    //     mutate()
    // }

    const handleSelectProvider = (value: string[]) => {
        if (value.length > 0) {
            setProviderSearch(Object.values(value).map((item: any) => item.toUpperCase()).join(","))
        } else {
            setProviderSearch(Object.keys(games).map((item: any) => item.toUpperCase()).join(","))
        }
    };

    // useMemo(() => {
    //     if (searchParams.get("search")) {
    //         setFilterText(searchParams.get("search") || "")
    //         setSearchMember(searchParams.get("search") || "")
    //         mutate()
    //     }
    // }, [searchParams, setSearch])// eslint-disable-line

    return (
        <>
            <TitleBar title={<FormattedMessage id={'main.winloss'} defaultMessage={'Win/Loss'} />} subTitle={""} routes={routes} />
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRangeShowtime onChangeDate={setDateRange} onDateActive={dateRange} funClear={true} />
                <DateFilterShowtime onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            {/* <div className="d-flex justify-content-start mb-4">
                <Search onChangeSearch={setFilterText} onHandleSearch={handleSearch} filterText={filterText} />
                <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">Search</button>
            </div> */}
            <Breadcrumb separator="" className="mb-3" style={{ fontSize: 16, cursor: "pointer" }}>
                {data && data.parents.map((item: any, index: any) => (
                    <Fragment key={`fragment-${item}-${index}`}>
                        {index === 0 ?
                            <Breadcrumb.Item key={`item-${item}-${index}`}
                                onClick={() => navigate(`/winloss`, {
                                    state: {
                                        startDate: dateRange.start,
                                        endDate: dateRange.end
                                    },
                                })} >{item}</Breadcrumb.Item>
                            :
                            <Breadcrumb.Item key={`item-${item}-${index}`}
                                onClick={() => navigate(`/winloss/${item}`, {
                                    state: {
                                        startDate: dateRange.start,
                                        endDate: dateRange.end
                                    },
                                })} >{item}</Breadcrumb.Item>

                        }
                        <Breadcrumb.Separator key={`separator-${item}`} />
                    </Fragment>
                ))}
                <Breadcrumb.Item key={`item${usernameSearch}`}>{usernameSearch}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <Space>
                    Select Provider:
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%', minWidth: '200px' }}
                        placeholder="Default All Provider"
                        onChange={handleSelectProvider}>
                        {Object.keys(games).map((keyGame: any) => (
                            <Select.Option key={keyGame} value={keyGame}>{games[keyGame].name}</Select.Option>
                        ))}
                    </Select>
                </Space>
            </div>
            <div className="box-white ant-box-table">
                <TableSummary
                    className="table-winloss"
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    totalCount={data?.totalCount || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                    bordered={true}
                    summary={(pageData: any) => {
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row style={{ textAlign: "center" }}>
                                    <Table.Summary.Cell index={0} colSpan={2}><Typography.Text strong>Total</Typography.Text></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}><Amount amount={dataSummary.turnover} /></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}><Amount amount={dataSummary.validTurnover} /></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}><Amount amount={dataSummary.stakeCount} decimal={false} /></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}><Amount amount={dataSummary.member?.winloss} /></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}><Amount amount={dataSummary.member?.commission} /></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}><Amount amount={dataSummary.member?.winloss + dataSummary.member?.commission} /></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}><Amount amount={dataSummary.agent?.winloss} /></Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}><Amount amount={dataSummary.agent?.commission} /></Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}><Amount amount={dataSummary.agent?.winloss + dataSummary.agent?.commission} /></Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}><Amount amount={dataSummary.company?.winloss} /></Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}><Amount amount={dataSummary.company?.commission} /></Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}><Amount amount={dataSummary.company?.winloss + dataSummary.company?.commission} /></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
            </div>
        </>
    );
};

export default WinLoss