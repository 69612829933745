import { CommonResponse, CommonResponseAPIKey } from '~types/api'
import api from '~utils/api'
// import { DateRangeType } from '~types/DatePicker'
import { QueryParams, QueryParamsDate } from '~types/queryParams'
import {
	Users,
	UserRole,
	UsersHistory,
	ViewUsersHistoryData,
	UserEdit,
	SubUserEdit,
	SubUsers,
	MemberAdd,
	AgentAdd,
	SubUserAdd,
	RestrictParams,
	PasswordParams,
	NewPasswordParams
} from '~types/users'
import {
	TransferAdd
} from '~types/transfer'
import {
	AccessLogs,
	PaymentLogs
} from '~types/logs'
import {
	PaymentStatements,
} from '~types/payment'
import {
	Feedback,
	FeedbackParams,
} from '~types/feedback'
import {
	WinLoss,
	WinLossMember,
	WinLossParams,
	WinLossMemberParams
} from '~types/reports'
import {
	Notification,
	NotificationAdd,
} from '~types/notification'
import {
	Announcer
} from '~types/announcer'
import { ActiveSecurity, InitializeSecurity } from '~types/security'
import { IProvider } from '~types/provider'
import { DashboardSummary } from '~types/dashboard'


class ServiceAPI {
	// private prefix: string = ''

	// constructor() {
	// const prefix = localStorage.getItem('x-prefix')
	// if (prefix) {
	// 	// this.setPrefix(prefix)
	// }
	// }


	getDashboardSummary(params?: QueryParamsDate): Promise<DashboardSummary> {
		return api.get<void, DashboardSummary>(`/ag/dashboard/summary`, {
			params: {
				startDate: params?.startDate,
				endDate: params?.endDate,
			},
		})
	}

	addMember(data: MemberAdd): Promise<CommonResponse> {
		return api.put<void, CommonResponse>('/ag/members', data)
	}

	addAgent(data: AgentAdd): Promise<CommonResponse> {
		return api.put<void, CommonResponse>('/ag/agents', data)
	}

	addSubUser(data: SubUserAdd): Promise<CommonResponse> {
		return api.put<void, CommonResponse>('/ag/sub-users', data)
	}

	getUser(username: string): Promise<Users> {
		return api.get<void, Users>(`/ag/users/${username}`)
	}

	getHistoryEditUser(username: string): Promise<UsersHistory> {
		return api.get<void, UsersHistory>(`/ag/users/${username}/logs`)
	}

	getViewHistoryEditUser(username: string, id: string): Promise<ViewUsersHistoryData> {
		return api.get<void, ViewUsersHistoryData>(`/ag/users/${username}/logs/${id}`)
	}

	updateUser(username: string, data: UserEdit): Promise<CommonResponse> {
		return api.put<void, CommonResponse>(`/ag/users/${username}`, data)
	}

	updateSubUser(username: string, data: SubUserEdit): Promise<CommonResponse> {
		return api.put<void, CommonResponse>(`/ag/sub-users/${username}`, data)
	}

	getUserChildren(username: string, role: UserRole, params?: QueryParams): Promise<Users> {
		return api.get<void, Users>(`/ag/users/${username}/children`, {
			params: {
				limit: params?.limit,
				page: params?.page,
				startDate: params?.startDate,
				endDate: params?.endDate,
				search: params?.search,
				role: role,
			},
		})
	}

	getSubUserList(params: QueryParams): Promise<SubUsers> {
		return api.get<void, SubUsers>(`/ag/sub-users`, {
			params: {
				limit: params.limit,
				page: params.page,
				startDate: params?.startDate,
				endDate: params?.endDate,
				search: params?.search,
			},
		})
	}

	getSubUser(username: string): Promise<SubUsers> {
		return api.get<void, SubUsers>(`/ag/sub-users/${username}`)
	}

	setUsersRestrict(username: string, data: RestrictParams): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/ag/users/${username}/restrict`, data)
	}

	setSubUsersRestrict(username: string, data: RestrictParams): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/ag/sub-users/${username}/restrict`, data)
	}

	setUsersChangePassword(username: string, data: PasswordParams): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/ag/users/${username}/password`, data)
	}

	setSubUsersChangePassword(username: string, data: PasswordParams): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/ag/sub-users/${username}/password`, data)
	}

	setUsersChangeNewPassword(data: NewPasswordParams): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/ag/me/change-password`, data)
	}

	setUsersDeposit(username: string, data: TransferAdd): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/ag/users/${username}/deposit`, data)
	}

	setUsersWithdraw(username: string, data: TransferAdd): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/ag/users/${username}/withdraw`, data)
	}

	refreshAPIKey(): Promise<CommonResponseAPIKey> {
		return api.patch<void, CommonResponseAPIKey>(`/ag/agents/refreshApiKey`)
	}

	refresh2FA(username: string): Promise<CommonResponseAPIKey> {
		return api.patch<void, CommonResponseAPIKey>(`/ag/security/${username}/refreshSecret`)
	}

	addNotification(data: NotificationAdd): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/ag/notifications`, data)
	}

	getNotifications(params: QueryParams): Promise<Notification> {
		return api.get<void, Notification>(`/ag/notifications`, {
			params: {
				limit: params.limit,
				page: params.page,
				startDate: params?.startDate,
				endDate: params?.endDate,
			},
		})
	}

	getAccessLogs(params: QueryParams): Promise<AccessLogs> {
		return api.get<void, AccessLogs>(`/ag/logs/access-logs`, {
			params: {
				limit: params.limit,
				page: params.page,
				startDate: params?.startDate,
				endDate: params?.endDate,
			},
		})
	}

	getPaymentLogs(params: QueryParams): Promise<PaymentLogs> {
		return api.get<void, PaymentLogs>(`/ag/logs/payment-logs`, {
			params: {
				limit: params.limit,
				page: params.page,
				startDate: params?.startDate,
				endDate: params?.endDate,
				search: params?.search,
			},
		})
	}

	getFeedbacks(params: FeedbackParams): Promise<Feedback> {
		return api.get<void, Feedback>(`/ag/feedbacks`, {
			params: {
				limit: params.limit,
				page: params.page,
				parent: params?.parent,
				type: params?.type,
				startDate: params?.startDate,
				endDate: params?.endDate,
				search: params?.search,
			},
		})
	}

	getReportWinLoss(params: WinLossParams): Promise<WinLoss> {
		return api.get<void, WinLoss>(`/ag/reports/daily-reports`, {
			params: {
				limit: params.limit,
				page: params.page,
				startDate: params?.startDate,
				endDate: params?.endDate,
				username: params?.username,
				providers: params?.providers,
			},
		})
	}
	getReportWinLossMember(params: WinLossMemberParams): Promise<WinLossMember> {
		return api.get<void, WinLossMember>(`/ag/reports/transactions`, {
			params: {
				limit: params.limit,
				page: params.page,
				username: params?.username,
				startDate: params?.startDate,
				endDate: params?.endDate,
			},
		})
	}

	getPaymentStatement(username: string, params: QueryParams): Promise<PaymentStatements> {
		return api.get<void, PaymentStatements>(`/ag/payments/statements/${username}`, {
			params: {
				limit: params.limit,
				page: params.page,
				startDate: params?.startDate,
				endDate: params?.endDate,
			},
		})
	}

	getAnnouncer(): Promise<Announcer> {
		return api.get<void, Announcer>(`/ag/announcer-agent`)
	}

	get2FASecurity(): Promise<InitializeSecurity> {
		return api.get<void, InitializeSecurity>(`/ag/security`)
	}

	active2FASecurity(data: ActiveSecurity): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/ag/security`, data)
	}


	getProviderPercentage(): Promise<IProvider> {
		return api.get<void, IProvider>(`/ag/providers/percentage`)
	}



}

export default new ServiceAPI()
