import useSWR from 'swr'
import ServiceAPI from '~services/services'
// import { DateRangeType } from '~types/IDateRange'
import { QueryParams } from '~types/queryParams'

export const useFetchPaymentStatement = (username: string, params: QueryParams) => {
    const { data, mutate, error } = useSWR([`/payments/statements/${username}?limit=${params?.limit}&page=${params?.page}`, params], () => ServiceAPI.getPaymentStatement(username, params));

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchPaymentStatement
