import useSWR from 'swr'
import ServiceAPI from '~services/services'
// import { DateRangeType } from '~types/IDateRange'
import { WinLossParams } from '~types/reports'

export const useFetchReportWinLoss = (params: WinLossParams) => {
    const { data, mutate, error } = useSWR([`/winloss?username=${params.username}&startDate=${params.startDate}&endDate=${params.endDate}`, params],
        () => ServiceAPI.getReportWinLoss(params)
    );

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchReportWinLoss
