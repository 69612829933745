import React, { FC, Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { useNavigate, useParams, Navigate } from "react-router-dom"
import { toast } from 'react-toastify'
import {
    Form,
    Input,
    InputNumber,
    Row,
    Col,
    Table,
    PageHeader,
    Divider,
    Space,
    Tabs,
    Select,
    Typography,
    Checkbox
} from 'antd'
import Button from "antd-button-color"
// import type { TableRowSelection } from 'antd/es/table/interface'
import to from 'await-to-js'
import {
    UserEdit,
} from '~types/users'
import TitleBar from '~components/Elements/TitleBar'
import Empty from '~components/Elements/Empty'
import Loading from '~components/Elements/Loading'
import EmptyComponents from '~components/Elements/Empty'
import HistoryEdit from '~components/Users/HistoryEdit'
import { ApplicationProviderState, ApplicationState } from '~store/index'
import { requestAuthInit } from '~store/auth/auth.actions'
import { ThunkDispatch } from '~types/store'
import { getGamesType } from '~utils/games' //getProvider
import PatternNumber from '~utils/PatternNumber'
// import NumberWithCommas from '~utils/NumberWithCommas'
import ServiceAPI from '~services/services'


const EditAgent: FC = () => {
    const { username } = useParams();
    const [form] = Form.useForm();
    let navigate = useNavigate();
    const { providers: providerState } = useSelector((state: ApplicationProviderState) => state.providers)

    const routes = [
        {
            path: '',
            breadcrumbName: 'Management',
        },
        {
            path: '/agents',
            breadcrumbName: 'Agents',
        },
        {
            path: '',
            breadcrumbName: 'Edit Agent',
        },
    ];

    const authState = useSelector((state: ApplicationState) => state.auth)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [indeterminate, setIndeterminate] = useState<boolean>(false)
    const [dataSource, setDataSource] = useState<any>([])
    const [dataLoading, setDataLoading] = useState<boolean>(false)
    const [userProducts, setUserProducts] = useState<any>([])
    const [productsChecked, setProductsChecked] = useState<any>([])
    // const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])


    const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);
    const toggleHistory = () => setIsHistoryOpen(!isHistoryOpen)

    const handleFetchData = async (username: string) => {
        if (!username) {
            setDataLoading(false)
            return <Navigate to="/agents" replace />
        }
        setDataSource([])
        setDataLoading(true)
        const [err, res] = await to(ServiceAPI.getUser(username))
        if (err) {
            toast.error(err?.message as string)
            setDataLoading(false)
        } else {
            setDataSource(res.data)
            setDataLoading(false)
        }
    }

    const updateData = async (username: string, values: UserEdit | any) => {
        if (!username) {
            setBtnLoading(false)
            return false
        }
        delete values.username
        const [err, res] = await to(ServiceAPI.updateUser(username, values))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
        } else {
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
            navigate(`/agents`)
        }
    }

    const handleFormSubmit = async () => {
        await form.validateFields()
            .then(async (values: UserEdit | any) => {
                setBtnLoading(true)
                // Object(Object.keys(values.products).map((item: any) => {
                //     let itemProductCheck = productsChecked[item]
                //     let itemProduct = values.products[item]
                //     itemProductCheck.givenPT = itemProduct.ownPT
                //     values.products[item] = itemProductCheck
                //     return true
                // }))
                values.products = await fetchDataProducts(values)
                delete values.balance
                delete values.currency

                updateData(values.username, values)
            })
            .catch((errorInfo) => {

            });
    }

    const fetchDataProducts = async (values: UserEdit) => {
        await Object.keys(productsChecked).forEach((item: any) => {
            let itemProductCheck = productsChecked[item]
            let itemProductMain = {
                ourPT: 0,
                ownPT: productsChecked[item].givenPT
            }
            let itemProduct = values.products[item] !== undefined ? values.products[item] : itemProductMain
            itemProductCheck.givenPT = itemProduct.ownPT
            values.products[item] = itemProductCheck
        })
        return values.products
    }

    // const listProducts = (gameType: string) => {
    //     if (userProducts.length > 0) {
    //         const products = Object.keys(userProducts).map((key: string, index: any) => {
    //             if (!userProducts[key].enabled) {
    //                 return []
    //             }
    //             const labelCheckbox = userProducts[key]
    //             const res = getProvider(labelCheckbox.key, providerState.data)
    //             res.enabled = userProducts[key].enabled
    //             res.ownPT = userProducts[key].ownPT
    //             return res
    //         })
    //         return products.filter((product: any) => product.type === gameType)
    //     }
    // }

    const handleCheckboxChangeAll = (e: any) => {
        onUpdateDataChecked(e.target.checked, 'all')
    }

    const handleCheckboxChange = (e: any, key: string) => {
        onUpdateDataChecked(e.target.checked, key)
    }

    const TabTypeProducts = Object.keys(getGamesType).map((item: string, index: any) => {
        // const productList: any = listProducts(item.toLocaleUpperCase())
        const productList: any = userProducts.filter((product: any) => product.type === item.toLocaleUpperCase() && product.enabled)
        const productType = (Object.values(getGamesType)[index]).toLocaleUpperCase()
        const columns = [
            {
                title: 'Game Name',
                dataIndex: 'name',
                key: `columns-name-${productType}`,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            <Checkbox
                                checked={Object(productsChecked)[`${record.key}`].enabled}
                                onChange={(e: any) => handleCheckboxChange(e, record.key)}
                            />
                            {record.name}
                        </Space>
                    )
                }
            },
            {
                title: 'Our Percentage',
                dataIndex: 'ourPT',
                key: `columns-ourPT-${productType}`,
                width: '200px',
                render: (text: any, record: any) => {
                    return (
                        <Form.Item
                            name={['products', `${record.key}`, 'ourPT']}
                            key={`select-ourPT-${record.key}`}>
                            <Select
                                placeholder="Select Our Percentage"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.join('').toLowerCase().includes(input.toLowerCase())
                                }
                                onSelect={
                                    (value: any) => {
                                        form.setFieldsValue({
                                            'products': {
                                                [`${record.key}`]: {
                                                    'ourPT': value,
                                                    'ownPT': record.ownPT - value
                                                }
                                            }
                                        })
                                    }
                                }>
                                {[...Array(record.ownPT + 1)].map((x, i) =>
                                    <Fragment key={`key-ourPT-${i}`}>
                                        <Select.Option value={i} key={`ourPT-${i}`}>{i.toString()}%</Select.Option>
                                    </Fragment>
                                )}
                            </Select>
                        </Form.Item>
                    )
                }
            },
            {
                title: 'Hold Percentage',
                dataIndex: 'ownPT',
                key: `columns-ownPT-${productType}`,
                width: '200px',
                render: (text: any, record: any) => {
                    return (
                        <Form.Item
                            name={['products', `${record.key}`, 'ownPT']}
                            key={`select-ownPT-${record.key}`}>
                            <Select
                                placeholder="Select Hold Percentage"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) =>
                                    option.children.join('').toLowerCase().includes(input.toLowerCase())
                                }
                                onSelect={
                                    (value: any) => {
                                        form.setFieldsValue({
                                            'products': {
                                                [`${record.key}`]: {
                                                    'ourPT': record.ownPT - value,
                                                    'ownPT': value
                                                }
                                            }
                                        })
                                    }
                                }>
                                {[...Array(record.ownPT + 1)].map((x, i) =>
                                    <Fragment key={`key-ownPT-${i}`}>
                                        <Select.Option value={i} key={`ownPT-${i}`}>{i.toString()}%</Select.Option>
                                    </Fragment>
                                )}
                            </Select>
                        </Form.Item>
                    )
                }
            },
        ];

        // const onSelectProducts = (selected: boolean, records: any) => {
        //     records.map((record: any, index: number) => {
        //         Object.assign(productsChecked, {
        //             [`${record.key}`]: {
        //                 'enabled': selected,
        //                 'givenPT': productsChecked[`${record.key}`].givenPT,
        //             }
        //         });
        //         return false
        //     })
        // }

        // const rowSelection: TableRowSelection<any> = {
        //     selectedRowKeys: selectedRowKeys.filter((item: any) => item.enabled).map((item: any) => item.key),
        //     onChange: (selectedRowKeys, selectedRows) => {
        //         // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        //         setSelectedRowKeys(selectedRows)
        //     },
        //     onSelect: (record, selected, selectedRows) => {
        //         // console.log(record, selected, selectedRows);
        //         onSelectProducts(selected, [record])
        //     },
        //     onSelectAll: (selected, selectedRows, changeRows) => {
        //         // console.log(selected, selectedRows, changeRows);
        //         onSelectProducts(selected, changeRows)
        //     },
        // };

        return {
            label: productType,
            key: index,
            children: (
                (productList && productList.length > 0) ? (
                    <Table
                        size={'small'}
                        className="table-products"
                        // rowSelection={rowSelection}
                        rowKey={(record: any) => record.key}
                        key={`table-${productType}`}
                        dataSource={productList}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: true }}
                        bordered />
                ) : <Empty key={`Empty ${productType}`} />
            ),
            disabled: productList && productList.length > 0 ? false : true
        }
    })


    const onUpdateDataChecked = (enabled: boolean, keyChange: any) => {
        let productsObj: object = {}
        Object.keys(productsChecked as object).forEach((key: any) => {
            if (key === keyChange || keyChange === "all") {
                Object.assign(productsObj, {
                    [`${key}`]: {
                        'enabled': enabled,
                        'givenPT': productsChecked[key].givenPT,
                    }
                });
            } else {
                Object.assign(productsObj, {
                    [`${key}`]: {
                        'enabled': productsChecked[key].enabled,
                        'givenPT': productsChecked[key].givenPT,
                    }
                });
            }
        })

        setProductsChecked(productsObj)
        // console.log(Object.values(productsObj).some((item: any) => !item.enabled));
        console.log(productsObj);
        console.log(Object.values(productsObj).every((item: any) => item.enabled));

        setIndeterminate(Object.values(productsObj).every((item: any) => item.enabled));
    }

    useEffect(() => {
        //fetch data show table
        if (form && username && providerState && Object.keys(providerState.data).length > 0) {
            form.resetFields();
            handleFetchData(username)
            let productsObj: object = {}
            // const test = 
            // console.log(Object.entries(authState.data.user?.products as object).filter(([k, v]) => v.enabled).map(([k, v]) => {});

            let authStateUserProduct: any = authState.data.user?.products ?
                Object.entries(authState.data.user?.products as object).filter(([k, v]) => v.enabled).map(([k, v]) => {
                    v.key = k
                    const dataProduct: any = providerState.data[v.key] as any
                    // rowSelected.push(v)
                    v.name = dataProduct.name
                    v.type = dataProduct.type
                    // if (v.enabled) {
                    form.setFieldsValue({
                        'products': {
                            [`${v.key}`]: {
                                'ourPT': 0,
                                'ownPT': v.ownPT
                            }
                        }
                    })
                    Object.assign(productsObj, {
                        [`${v.key}`]: {
                            'enabled': false,
                            'givenPT': v.ownPT,
                        }
                    });
                    // }
                    return v
                })
                : []
            setProductsChecked(productsObj)
            setUserProducts(authStateUserProduct)
        }
    }, [form, username, authState, providerState])

    useEffect(() => {
        //update data table
        // let rowSelected: any[] = []
        if (form && dataSource !== undefined && Object.keys(dataSource).length > 0 && providerState && Object.keys(providerState.data).length > 0) {
            form.setFieldsValue(dataSource)
            // Object.entries(dataSource?.products as object).forEach(([k, v]) => {
            //     v.key = k
            //     const res: any = providerState.data[v.key] as any
            //     res.enabled = v.enabled
            //     res.ownPT = v.ownPT
            //     // if (res.enabled) {
            //     //     rowSelected.push(res)
            //     // }
            //     form.setFieldsValue({
            //         'products': {
            //             [`${v.key}`]: {
            //                 'ourPT': productsChecked[k].givenPT - v.ownPT,
            //                 'ownPT': v.ownPT
            //             }
            //         }
            //     })
            //     Object.assign(productsChecked, {
            //         [`${k}`]: {
            //             'enabled': v.enabled,
            //             'givenPT': v.ownPT,
            //         }
            //     });
            // })
            Object.keys(productsChecked as object).forEach((k) => {
                let v = dataSource?.products[k]
                v.key = k
                const res: any = providerState.data[v.key] as any
                res.enabled = v.enabled
                res.ownPT = v.ownPT
                form.setFieldsValue({
                    'products': {
                        [`${v.key}`]: {
                            'ourPT': productsChecked[k].givenPT - v.ownPT,
                            'ownPT': v.ownPT
                        }
                    }
                })
                Object.assign(productsChecked, {
                    [`${k}`]: {
                        'enabled': v.enabled,
                        'givenPT': v.ownPT,
                    }
                });
            }, {})

            setIndeterminate(Object.values(productsChecked).every((item: any) => item.enabled));

            // setSelectedRowKeys(rowSelected)
        }
    }, [form, dataSource, providerState]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <TitleBar title="Agents" routes={routes} />
            <PageHeader
                className="site-page-header"
                onBack={() => navigate(`/agents`)}
                title="Edit Agent"
                extra={[
                    <Button
                        type="info"
                        key={`history`}
                        icon={<i className="fa-solid fa-history me-3"></i>}
                        onClick={toggleHistory}
                    >History</Button>,
                ]}
            />
            {dataLoading ? <Loading /> : (
                dataSource && Object.keys(dataSource).length > 0
                    ? (
                        <div className="box-white ant-box-card  mt-3 mt-md-0">
                            <Form
                                form={form}
                                name="editAgent"
                                layout="vertical">
                                <Typography.Title level={4} style={{ marginBottom: 16 }}>Agent Information</Typography.Title>
                                <Row gutter={16}>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="username"
                                            label="Username"
                                            rules={[{ required: false, message: 'Please input your username!' }]}>
                                            <Input placeholder="Username" inputMode="text" autoComplete="new-username" disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            rules={[{ required: true, message: 'Please input your name!' }]}
                                            hasFeedback>
                                            <Input placeholder="Name" inputMode="text" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="balance"
                                            label="Credit"
                                            rules={[
                                                { required: false, message: 'Please input your credit!' },
                                                { pattern: PatternNumber, message: 'Please enter numbers only!' },
                                                // { type: 'number', min: 0, max: authState.data.user?.balance, message: `Please enter a number between 0 and ${NumberWithCommas(authState.data.user?.balance)}!` }
                                            ]}>
                                            <InputNumber
                                                placeholder="Credit"
                                                inputMode="numeric"
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/\.(?=\d{0,2}$)/g, ".")}
                                                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                                                controls={false}
                                                disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="currency"
                                            label="Currency"
                                            rules={[{ required: false, message: 'Please select your currency!' }]}>
                                            <Input placeholder="Currency" inputMode="text" disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="msnPrefix"
                                            label="MSN Prefix"
                                            rules={[{ required: false, message: 'Please input your MSN Prefix!' }]}
                                            hasFeedback>
                                            <Input placeholder="MSN Prefix" inputMode="text" autoComplete="new-msnprefix" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Typography.Title level={4} style={{ marginBottom: 16 }}>Contact Information</Typography.Title>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="person"
                                            label="Person In Charge">
                                            <Input placeholder="Person In Charge" inputMode="text" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="email"
                                            label="Email"
                                            rules={[{ type: 'email' }]}>
                                            <Input placeholder="Email" inputMode="email" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="phone"
                                            label="Phone Number">
                                            <Input placeholder="Phone Number" inputMode="tel" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="other"
                                            label="Other Contact">
                                            <Input placeholder="Other Contact" inputMode="text" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Typography.Title level={4} style={{ marginBottom: 16 }}>Product Settings</Typography.Title>
                                <Checkbox
                                    checked={indeterminate}
                                    onChange={(e: any) => handleCheckboxChangeAll(e)}>
                                    Select All
                                </Checkbox>
                                <Tabs defaultActiveKey="0" centered items={TabTypeProducts} />
                                <Divider />
                                <div className="drawer-actions">
                                    <Space>
                                        <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large" className="text-white me-2"><i className="fa-solid fa-save me-2"></i>Save</Button>
                                    </Space>
                                </div>
                            </Form>
                        </div >
                    ) : (<EmptyComponents />)
            )}
            {username && isHistoryOpen && (
                <HistoryEdit
                    isOpen={isHistoryOpen}
                    close={() => toggleHistory()}
                    username={username}
                    role={dataSource.role}
                />
            )}
        </Fragment >
    )
}

export default EditAgent