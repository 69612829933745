import React, { FC, useState, useEffect, useMemo } from 'react'
import { Modal, Button, Typography, Form } from 'antd'
import NumberWithCommas from '~utils/NumberWithCommas'
import moment from 'moment'
// import { toast } from 'react-toastify'
// import to from 'await-to-js'
import DatePickerRange from '~components/Elements/DatePickerRange'
import DateFilter from '~components/Elements/DateFilter'
import Table from '~components/Elements/Table'
import BadgeComponent from '~components/Elements/BadgeComponent'
import useDateRange from '~hooks/useDateRange'
import useFetchPaymentStatement from '~hooks/useFetchPaymentStatement'
import useDatePagination from '~hooks/useDatePagination'
// import ServiceAPI from '~services/services'

interface ModalProps {
    close: () => void
    isOpen: boolean

    data: any
    onloadData: () => void
}

const Statement: FC<ModalProps> = ({ isOpen, close, data, onloadData }) => {
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState<any>([])
    const { dateRange, setDateRange } = useDateRange()
    const { pagination, setPagination } = useDatePagination()
    // const [switchLoading, setSwitchLoading] = useState<boolean>(false)
    const { data: dataStatement, mutate, isLoading } = useFetchPaymentStatement(data.username, {
        page: pagination.page,
        limit: pagination.pageSize,
        startDate: dateRange.start,
        endDate: dateRange.end,
    })
    // const handleFetchPaymentStatement = async (username: string) => {
    //     if (username) {
    //         setSwitchLoading(true)
    //         const [err, res] = await to(ServiceAPI.getPaymentStatement(username, { page: pagination.page, limit: pagination.pageSize }))
    //         if (err) {
    //             toast.error(err?.message as string)
    //             setSwitchLoading(false)
    //         } else {
    //             setDataSource(res.data)
    //             setSwitchLoading(false)
    //         }
    //     }
    // }

    const columns = [
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            width: '140px',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'Sender',
            dataIndex: 'sender',
            render: (text: any, record: any) => (
                <div>{record.sender}</div>
            )
        },
        {
            title: 'Receiver',
            dataIndex: 'Receiver',
            render: (text: any, record: any) => (
                <div>{record.receiver}</div>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (text: any, record: any) => (
                <BadgeComponent className={record.action === "DEPOSIT" ? "badge-success" : "badge-danger"} text={record.action} />
            )
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    {NumberWithCommas(record.amount, 2)}
                </div>
            )
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            align: 'center',
            render: (text: any, record: any) => (
                <div>{record.currency}</div>
            )
        },
        {
            title: 'Operated by',
            dataIndex: 'createdBy',
            render: (text: any, record: any) => (
                <div>{record.createdBy}</div>
            )
        },
    ]

    useEffect(() => {
        if (isOpen && data) {
            setDataSource([])
            if (dataStatement !== undefined && dataStatement.totalCount > 0) {
                setDataSource(dataStatement.data)
            }
            // handleFetchPaymentStatement(data.username)
        }
    }, [form, isOpen, data, dataStatement])// eslint-disable-line


    useMemo(() => {
        // handleFetchPaymentStatement(data.username)
        mutate()
    }, [setDateRange, setPagination])// eslint-disable-line

    return (
        <Modal
            title={`Statement`}
            open={isOpen}
            onCancel={close}
            centered
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .80}
            footer={[
                <Button key="close" onClick={close}>Close</Button>,
            ]}>
            <Typography.Title level={5}>
                Username : {data.username}
            </Typography.Title>

            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} />
                <DateFilter onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            <div className="box-white ant-box-table">
                <Table
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    totalCount={data?.totalCount || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>

        </Modal>
    )
}


export default Statement