import useSWR from 'swr'
import ServiceAPI from '~services/services'
// import { DateRangeType } from '~types/IDateRange'
import { FeedbackParams } from '~types/feedback'

export const useFetchFeedbacks = (params: FeedbackParams, refreshInterval: number) => {
    const { data, mutate, error } = useSWR([`/feedbacks?limit=${params?.limit}&page=${params?.page}`, params], () =>
        ServiceAPI.getFeedbacks(params), { refreshInterval });

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchFeedbacks
