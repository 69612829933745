import React, { FC, useEffect, useState } from 'react'
import {
    Divider,
    Descriptions,
    Tabs,
    Typography,
    Empty,
} from 'antd'
import moment from 'moment'
import NumberWithCommas from '~utils/NumberWithCommas'
import { getGamesType } from '~utils/games'
import { ApplicationProviderState } from '~store/index'
import { useSelector } from 'react-redux'

interface ViewProps {
    dataSource: any
}

const ViewAgent: FC<ViewProps> = ({ dataSource }) => {
    const [dataProducts, setDataProducts] = useState<any>([])
    const { providers: providerState } = useSelector((state: ApplicationProviderState) => state.providers)

    // const listProducts = (gameType: string) => {
    //     if (dataProducts.length > 0) {
    //         const products = Object.keys(dataProducts).map((key: string, index: any) => {
    //             if (!dataProducts[key].enabled) {
    //                 return []
    //             }
    //             const labelCheckbox = dataProducts[key]
    //             const res = getGames(labelCheckbox.key)
    //             res.enabled = dataProducts[key].enabled
    //             res.ownPT = dataProducts[key].ownPT
    //             return res
    //         })
    //         return products.filter((product: any) => product.type === gameType)
    //     }
    // }

    const TabTypeProducts = Object.keys(getGamesType).map((item: string, index: any) => {
        // const productList: any = listProducts(item.toLocaleUpperCase())
        const productList: any = dataProducts.filter((product: any) => product.type === item.toLocaleUpperCase())
        const productType = (Object.values(getGamesType)[index]).toLocaleUpperCase()

        const productElement = productList && productList.length > 0 ? productList.map((product: any) => {
            if (!product.enabled) {
                return ""
            }
            return (
                <Descriptions.Item label={product.name} key={product.key}>
                    {product.ownPT}%
                </Descriptions.Item>
            )
        }) : "";

        return {
            label: productType,
            key: index,
            children: (
                (productList && productList.length > 0) ? (
                    <Descriptions column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }} bordered>
                        <Descriptions.Item label={<span style={{ fontWeight: "bold" }}>Game Name</span>} key={0}>
                            <span style={{ fontWeight: "bold" }}>Percentage</span>
                        </Descriptions.Item>
                        {productElement}
                    </Descriptions>
                ) : <Empty key={`Empty ${productType}`} />
            ),
            disabled: productList && productList.length > 0 ? false : true
        }
    })

    useEffect(() => {
        setDataProducts([])
        if (dataSource !== undefined && Object.keys(dataSource).length > 0) {
            const productIn = Object.keys(dataSource.products).map((key: string) => {
                // const game = getGames(key)
                const provider = providerState.data[key]
                return {
                    "key": key,
                    "name": provider.name,
                    "type": provider.type,
                    "ownPT": dataSource.products[key].ownPT,
                    "enabled": dataSource.products[key].enabled,
                }
            })
            setDataProducts(productIn);
        }
    }, [dataSource, providerState])

    return (
        <Tabs
            defaultActiveKey="0"
            centered
            items={[
                {
                    label: "Agent",
                    key: "0",
                    children: (
                        <>
                            <Descriptions title={"Agent Information"} column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                                <Descriptions.Item label="Username">{dataSource.username}</Descriptions.Item>
                                <Descriptions.Item label="Name">{dataSource.name}</Descriptions.Item>
                                <Descriptions.Item label="Role">{dataSource.role}</Descriptions.Item>
                                <Descriptions.Item label="Credit">{NumberWithCommas(dataSource.balance, 2)}</Descriptions.Item>
                                <Descriptions.Item label="Currency">{dataSource.currency}</Descriptions.Item>
                                <Descriptions.Item label="MSN Prefix">{dataSource.msnPrefix ? dataSource.msnPrefix : "-"}</Descriptions.Item>
                            </Descriptions>
                            {/* {dataSource.apiKey && (
                                <>
                                    <Divider />
                                    <Descriptions title={"API Key"} column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                                        <Descriptions.Item label="APIKey">{dataSource.apiKey ? dataSource.apiKey : "-"}</Descriptions.Item>
                                    </Descriptions>
                                </>
                            )} */}
                            <Divider />
                            <Descriptions title={"Contact Information"} column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                                <Descriptions.Item label="Person In Charge">{dataSource.person || "-"}</Descriptions.Item>
                                <Descriptions.Item label="Email">{dataSource.email || "-"}</Descriptions.Item>
                                <Descriptions.Item label="Phone Number">{dataSource.phone || "-"}</Descriptions.Item>
                                <Descriptions.Item label="Other Contact">{dataSource.other || "-"}</Descriptions.Item>
                            </Descriptions>
                            <Divider />
                            <Descriptions title="System Information" column={1} labelStyle={{ justifyContent: 'flex-end', minWidth: 100 }}>
                                <Descriptions.Item label="Lock">
                                    {dataSource.lock
                                        ? <div className="msn-badge badge-danger ms-2">Lock</div>
                                        : <div className="msn-badge badge-success ms-2">Unlock</div>}
                                </Descriptions.Item>
                                <Descriptions.Item label="Suspended">
                                    {dataSource.suspended
                                        ? <div className="msn-badge badge-danger ms-2">Yes</div>
                                        : <div className="msn-badge badge-success ms-2">No</div>}
                                </Descriptions.Item>
                                <Descriptions.Item label="CreatedBy">{dataSource.createdBy}</Descriptions.Item>
                                <Descriptions.Item label="CreatedAt">{moment(dataSource.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Descriptions.Item>
                                <Descriptions.Item label="UpdatedBy">{dataSource.updatedBy}</Descriptions.Item>
                                <Descriptions.Item label="UpdatedAt">{moment(dataSource.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</Descriptions.Item>
                                <Descriptions.Item label="Last login">
                                    {dataSource.loginAt !== "1970-01-01 07:00:00"
                                        ? (<>
                                            {moment(dataSource.loginAt).format('DD/MM/YYYY HH:mm:ss')}
                                            <div className="msn-badge badge-light ms-2">{moment(dataSource.loginAt).fromNow()}</div>
                                        </>) : "-"}
                                </Descriptions.Item>
                                <Descriptions.Item label="Login IP">{dataSource.loginIP}</Descriptions.Item>
                            </Descriptions>
                        </>
                    )
                },
                {
                    label: "Product",
                    key: "1",
                    children: (
                        <>
                            <Typography.Title level={5}>Products Information</Typography.Title>
                            <Tabs defaultActiveKey="0" items={TabTypeProducts} centered />
                        </>
                    )
                }
            ]}
        />
    )
}

export default ViewAgent