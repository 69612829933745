import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '~store/index'
import { Modal, Descriptions, Space, message, Tooltip } from 'antd'
import Button from "antd-button-color"
import NumberWithCommas from '~utils/NumberWithCommas'
import ChangePasswordAccount from './ChangePasswordAccount'
import { ExclamationCircleOutlined, CopyOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import to from 'await-to-js'
import ServiceAPI from '~services/services'

interface ModalProps {
    close: () => void
    isOpen: boolean
}

const Profile: FC<ModalProps> = ({ isOpen, close }) => {
    const authState = useSelector((state: ApplicationState) => state.auth)
    const [dataUser, setDataUser] = useState<any>([])

    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState<boolean>(false);
    const toggleModalChangePassword = () => setIsChangePasswordOpen(!isChangePasswordOpen)


    const handleCopy = (values: string) => {
        navigator.clipboard.writeText(values)
        message.success('Copy successfully');
    }

    const toggleRefreshAPIKey = async (username: string) => {
        Modal.confirm({
            title: `You want to refresh API Key #${username}?`,
            icon: <ExclamationCircleOutlined />,
            content: `Make sure you want to refresh API Key #${username}`,
            okText: 'Yes',
            cancelText: 'No',
            centered: true,
            onOk() {
                (async () => {
                    const [err, res] = await to(ServiceAPI.refreshAPIKey())
                    if (err) {
                        toast.error(err?.message as string)
                    } else {
                        Modal.success({
                            title: res.message,
                            content: (
                                <>
                                    {res.apiKey}
                                    <Tooltip title="Copy APIKey">
                                        <Button icon={<CopyOutlined />} className="ms-2 mt2" disabled={res.apiKey ? false : true} onClick={() => handleCopy(res.apiKey)} />
                                    </Tooltip>
                                </>
                            ),
                            centered: true,
                        })
                    }
                })();
            },
            onCancel() {

            },
        });
    };

    const widthModal = (widthScreen: number) => {
        if (widthScreen >= 1920) {
            return global.window.innerWidth * .3
        }

        if (widthScreen >= 1200) {
            return global.window.innerWidth * .5
        }

        return widthScreen
    }

    useEffect(() => {
        if (isOpen && authState.data.user) {
            setDataUser(authState.data.subUser ? authState.data.subUser : authState.data.user)
        }
    }, [authState, isOpen])

    return (
        <Modal
            title={`Profile`}
            open={isOpen}
            onCancel={close}
            centered
            width={widthModal(global.window.innerWidth)}
            footer={[
                <Button key="refresh-apikey"
                    type="danger"
                    size="large"
                    icon={<i className="fa-solid fa-key-skeleton me-2"></i>}
                    onClick={() => {
                        toggleRefreshAPIKey(dataUser.username);
                    }}
                >
                    Refresh APIKey
                </Button>,
                <Button key="change-password"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={toggleModalChangePassword}>
                    <i className="fa-solid fa-key me-1"></i> Change Password</Button>,
                <Button key="close" onClick={close} size="large">Close</Button>,
            ]}>
            {dataUser && (
                <>
                    <Space align="start" style={{ display: 'flex' }}>
                        <Descriptions title="Profile Information" column={1}>
                            <Descriptions.Item label="Name">{dataUser.name || "-"}</Descriptions.Item>
                            <Descriptions.Item label="Username">{dataUser.username}</Descriptions.Item>
                            <Descriptions.Item label="Role">{authState?.data?.user?.role}</Descriptions.Item>
                            <Descriptions.Item label="Credit">{NumberWithCommas(authState?.data?.user?.balance, 2)}</Descriptions.Item>
                            <Descriptions.Item label="Currency">{authState?.data?.user?.currency ? authState?.data?.user?.currency : "THB"}</Descriptions.Item>
                            <Descriptions.Item label="MSN Prefix">{authState?.data?.user?.msnPrefix || "-"}</Descriptions.Item>
                        </Descriptions>
                        <Descriptions title="Contact Information" column={1}>
                            <Descriptions.Item label="Phone number">{dataUser?.phone || "-"}</Descriptions.Item>
                            <Descriptions.Item label="Other">{dataUser?.other || "-"}</Descriptions.Item>
                        </Descriptions>
                    </Space>
                    <ChangePasswordAccount
                        isOpen={isChangePasswordOpen}
                        close={toggleModalChangePassword}
                        username={dataUser?.username}
                    />
                </>
            )}

        </Modal>
    )
}

export default Profile