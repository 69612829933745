import React, { FC } from 'react'
import NumberWithCommas from '~utils/NumberWithCommas'

interface AmountProps {
    amount: number
    decimal?: boolean
    className?: string
}

const Amount: FC<AmountProps> = ({ amount, className, decimal = true }) => {

    return (
        <span className={`${amount < 0 && "text-red"} ${className ? className : ""}`} style={{justifyContent: "flex-end"}}>
            {NumberWithCommas(amount, decimal ? 2 : 0)}
        </span>
    )
}

export default Amount
