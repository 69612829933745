import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { useNavigate } from "react-router-dom"
import Button from "antd-button-color"
import { useSelector } from 'react-redux'
import moment from 'moment'
import { ApplicationState } from '~store/index'
import NumberWithCommas from '~utils/NumberWithCommas'
import TitleBar from '~components/Elements/TitleBar'
import FormattedMessage from '~components/Elements/FormattedMessage'
import DatePickerRange from '~components/Elements/DatePickerRange'
import DateFilter from '~components/Elements/DateFilter'
import Table from '~components/Elements/Table'
// import BadgeComponent from '~components/Elements/BadgeComponent'
// import View from './View'
// import Search from '~components/Elements/Search'
import useDateRangeEmpty from "~hooks/useDateRangeEmpty"
import useDatePagination from "~hooks/useDatePagination"
import useFetchNotifications from "~hooks/useFetchNotifications"

const Notification = () => {
    const authState = useSelector((state: ApplicationState) => state.auth)
    const permissions = authState.data.permissions
    let navigate = useNavigate();
    const routes = [
        {
            path: '',
            breadcrumbName: 'Notification',
        },
    ];

    // const [dataUsername, setDataUsername] = useState<string>(authStateUser?.username)
    // const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
    // const toggleView = () => setIsViewOpen(!isViewOpen)

    // const [filterText, setFilterText] = useState<string>("")
    // const [search, setSearch] = useState<string>("")
    // const [dataNotification, setDataNotification] = useState<any>([])
    const [dataSource, setDataSource] = useState<any>([])
    const { pagination, setPagination } = useDatePagination()
    const { dateRange, setDateRange } = useDateRangeEmpty()
    const { data, mutate, isLoading } = useFetchNotifications({
        page: pagination.page,
        limit: pagination.pageSize,
        startDate: dateRange.start,
        endDate: dateRange.end,
    })


    const columns: any = [
        // {
        //     title: 'From',
        //     dataIndex: 'from',
        //     ellipsis: true,
        //     render: (text: any, record: any) => (
        //         <div className="row-member" style={{ minWidth: 70 }}>
        //             <div
        //             // className="text-link"
        //             // onClick={() => {
        //             // setDataNotification(record)
        //             // toggleView()
        //             // }}
        //             >
        //                 {record.from}
        //             </div>
        //         </div>
        //     )
        // },
        {
            title: 'Description',
            dataIndex: 'description',
            render: (text: any, record: any) => (
                <div style={{ minWidth: 60 }}>
                    {record.description}
                </div>
            )
        },
        {
            title: 'Member Received',
            dataIndex: 'memberReceived',
            align: 'center',
            render: (text: any, record: any) => (
                <div className="row-amount">
                    {NumberWithCommas(record.memberReceived)}
                </div>
            )
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            ellipsis: true,
            width: '140',
            align: 'center',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.createdAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.createdAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
    ];

    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data.totalCount > 0) {
            setDataSource(data.data)
        }
        return () => {
            setDataSource([])
        }
    }, [data, isLoading, setDataSource])

    useMemo(() => {
        mutate()
    }, [setDateRange, setPagination])// eslint-disable-line

    return (
        <>
            <TitleBar title={<FormattedMessage id={'main.notification'} defaultMessage={'Notification'} />} subTitle={`${NumberWithCommas(data?.totalCount || 0)} items`} routes={routes} />
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} funClear={true} />
                <DateFilter onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            {/* <div className="d-flex justify-content-start mb-4">
                <SearchData onChangeSearch={setFilterText} onHandleSearch={handleSearch} filterText={filterText} />
                <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">Search</button>
            </div> */}
            {permissions && permissions["NOTIFICATION-MANAGEMENT"] === "EDIT" && (
                <div className="row mb-md-3 align-items-md-center">
                    <div className="mb-3 mb-md-0">
                        <Button
                            type="primary"
                            className="d-block ms-auto"
                            icon={<i className="fa-solid fa-plus me-3"></i>}
                            onClick={() => navigate(`add`)}>
                            Add Notification
                        </Button>
                    </div>
                </div>
            )}
            <div className="box-white ant-box-table">
                <Table
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    totalCount={data?.totalCount || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>


            {/* <View
                isOpen={isViewOpen}
                close={() => toggleView()}
                username={dataNotification?.username}
                swapEdit={() => toggleView()}
            /> */}
        </>
    );
};

export default Notification