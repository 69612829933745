export const permissions: any = {
    "USER-MANAGEMENT": {
        "name": "User Management",
    },
    "SUB-USER-MANAGEMENT": {
        "name": "Sub User Management",
    },
    "PAYMENT": {
        "name": "Payment",
    },
    "REPORT": {
        "name": "Report",
    },
    "FEEDBACK": {
        "name": "Feedback",
    },
    "NOTIFICATION-MANAGEMENT": {
        "name": "Notification",
    },
    "ANNOUNCEMENT-MANAGEMENT": {
        "name": "Announcement",
    },
    "PAYMENT-LOGS": {
        "name": "Payment Logs",
    },
    "ACCESS-LOGS": {
        "name": "Access Logs",
    },
}


export const permissionRadioOption: any = [
    { label: "Off", value: "OFF" },
    { label: "View", value: "VIEW" },
    { label: "Edit", value: "EDIT" },
]