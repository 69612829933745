import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { useNavigate } from "react-router-dom"
import { Space, Switch, Tooltip, Modal, Typography } from 'antd'
import Button from "antd-button-color"
import { PlusOutlined, MinusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { toast } from 'react-toastify'
import to from 'await-to-js'
import { ApplicationState } from '~store/index'
import NumberWithCommas from '~utils/NumberWithCommas'
import TitleBar from '~components/Elements/TitleBar'
import FormattedMessage from '~components/Elements/FormattedMessage'
import DatePickerRange from '~components/Elements/DatePickerRange'
import DateFilter from '~components/Elements/DateFilter'
import Table from '~components/Elements/Table'
// import BadgeComponent from '~components/Elements/BadgeComponent'
import Deposit from '~components/Payment/Transfer/Deposit'
import Withdraw from '~components/Payment/Transfer/Withdraw'
import Statement from '~components/Payment/Statement/Statement'
import ChangePassword from '~components/Users/ChangePassword'
import Search from '~components/Elements/Search'
import View from './View'
import useDateRangeEmpty from "~hooks/useDateRangeEmpty"
import useDatePagination from "~hooks/useDatePagination"
import useFetchUsers from "~hooks/useFetchUsers"
import ServiceAPI from '~services/services'

const Members = () => {
    const authState = useSelector((state: ApplicationState) => state.auth)
    const permissions = authState.data.permissions
    const authStateUser = authState.data.user
    let navigate = useNavigate();
    const routes = [
        {
            path: '',
            breadcrumbName: 'Management',
        },
        {
            path: '',
            breadcrumbName: 'Members',
        },
    ];

    const { confirm } = Modal;

    const [switchLoading, setSwitchLoading] = useState<boolean>(false)

    const [isDepositOpen, setIsDepositOpen] = useState<boolean>(false);
    const toggleModalDeposit = () => setIsDepositOpen(!isDepositOpen)
    const [isWithdrawOpen, setIsWithdrawOpen] = useState<boolean>(false);
    const toggleModalWithdraw = () => setIsWithdrawOpen(!isWithdrawOpen)
    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState<boolean>(false);
    const toggleModalChangePassword = () => setIsChangePasswordOpen(!isChangePasswordOpen)
    const [isStatementOpen, setIsStatementOpen] = useState<boolean>(false);
    const toggleModalStatement = () => setIsStatementOpen(!isStatementOpen)
    const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
    const toggleView = () => setIsViewOpen(!isViewOpen)

    const [filterText, setFilterText] = useState<string>("")
    const [search, setSearch] = useState<string>("")
    const [dataMember, setDataMember] = useState<any>([])
    const [dataSource, setDataSource] = useState<any>([])
    const { pagination, setPagination } = useDatePagination()
    const { dateRange, setDateRange } = useDateRangeEmpty()
    const { data, mutate, isLoading } = useFetchUsers(authStateUser ? authStateUser.username : "", "MEMBER", {
        page: pagination.page,
        limit: pagination.pageSize,
        startDate: dateRange.start,
        endDate: dateRange.end,
        search: search
    })

    const handleStatus = async (status: boolean, type: string, username: string) => {
        confirm({
            title: `You want to adjust your account ${type} #${username}?`,
            icon: <ExclamationCircleOutlined />,
            content: `Make sure you want to adjust your account ${type}. #${username}`,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                (async () => {
                    let data = {}
                    if (type === "Lock") {
                        data = { lock: status }
                    } else if (type === "Suspended") {
                        data = { suspended: status }
                    }
                    const [err, res] = await to(ServiceAPI.setUsersRestrict(username, data))
                    if (err) {
                        toast.error(err?.message as string)
                        setSwitchLoading(false)
                    } else {
                        mutate()
                        toast.success(res.message)
                        setSwitchLoading(false)
                    }
                })();
            },
            onCancel() {
                setSwitchLoading(false)
            },
        });
    };

    const columns: any = [
        {
            title: 'Username',
            dataIndex: 'username',
            ellipsis: true,
            // width: '160',
            render: (text: any, record: any) => (
                <div className="row-member" style={{ minWidth: 70 }}>
                    <div
                        className="text-link"
                        onClick={() => {
                            setDataMember(record)
                            toggleView()
                        }}>
                        <Typography.Text strong>{record.username}</Typography.Text>
                    </div>
                </div>
            )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            ellipsis: true,
            // width: '160',
            render: (text: any, record: any) => (
                <div className="row-member" style={{ minWidth: 60 }}>
                    {record.name}
                </div>
            )
        },
        // {
        //     title: 'Level',
        //     dataIndex: 'level',
        //     ellipsis: true,
        //     align: 'center',
        //     render: (text: any, record: any) => {
        //         return <BadgeComponent className={"badge-info"} text={record.role} />
        //     }
        // },
        {
            title: 'Credit',
            dataIndex: 'credit',
            ellipsis: true,
            render: (text: any, record: any) => (
                <Tooltip title="Credit">
                    <div className="row-amount">
                        {NumberWithCommas(record.balance, 2)}
                    </div>
                </Tooltip>
            )
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            align: 'center',
            render: (text: any, record: any) => (
                <div style={{ minWidth: 60 }}>
                    {(record.currency).toUpperCase()}
                </div>
            )
        },
        {
            title: 'Deposit / Withdraw',
            dataIndex: 'deposit-withdraw',
            ellipsis: true,
            align: 'center',
            hidden: permissions && (permissions["PAYMENT"] === "VIEW" || permissions["PAYMENT"] === "OFF"),
            render: (text: any, record: any) => (
                <div style={{ minWidth: 120 }}>
                    <Tooltip title="Deposit">
                        <Button type="success"
                            shape="circle"
                            icon={<PlusOutlined />}
                            size="small"
                            onClick={() => {
                                setDataMember(record)
                                toggleModalDeposit();
                            }} />
                    </Tooltip>
                    <Tooltip title="Withdraw" className="ms-3">
                        <Button type="danger"
                            shape="circle"
                            icon={<MinusOutlined />}
                            size="small"
                            onClick={() => {
                                setDataMember(record)
                                toggleModalWithdraw();
                            }} />
                    </Tooltip>
                </div>
            )
        },
        {
            title: 'Statement',
            dataIndex: 'statement',
            ellipsis: true,
            align: 'center',
            hidden: permissions && permissions["PAYMENT"] === "OFF",
            render: (text: any, record: any) => (
                <Button
                    type="success"
                    size="small"
                    icon={<i className="fa-solid fa-money-bill-transfer me-2"></i>}
                    onClick={() => {
                        setDataMember(record)
                        toggleModalStatement();
                    }}
                >
                    Statement
                </Button>
            )
        },
        {
            title: 'Lock',
            dataIndex: 'lock',
            ellipsis: true,
            // width: '90',
            align: 'center',
            render: (text: any, record: any) => {
                return (
                    <Space direction="vertical">
                        <Tooltip title="Lock/Unlock">
                            <Switch
                                checkedChildren="Lock"
                                unCheckedChildren="Unlock"
                                checked={record.lock}
                                loading={switchLoading}
                                disabled={permissions && (permissions["USER-MANAGEMENT"] === "VIEW" || permissions["USER-MANAGEMENT"] === "OFF")}
                                onChange={(e) => {
                                    setSwitchLoading(true)
                                    handleStatus(e, "Lock", record.username)
                                }} />
                        </Tooltip>
                    </Space>
                )
            }
        },
        {
            title: 'Suspended',
            dataIndex: 'suspended',
            ellipsis: true,
            // width: '180',
            align: 'center',
            render: (text: any, record: any) => {
                return (
                    <Space direction="vertical" style={{ minWidth: 70 }}>
                        <Tooltip title="Suspended">
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={record.suspended}
                                loading={switchLoading}
                                disabled={permissions && (permissions["USER-MANAGEMENT"] === "VIEW" || permissions["USER-MANAGEMENT"] === "OFF")}
                                onChange={(e) => {
                                    setSwitchLoading(true)
                                    handleStatus(e, "Suspended", record.username)
                                }} />
                        </Tooltip>
                    </Space>
                )
            }
        },
        {
            title: 'UpdatedAt',
            dataIndex: 'updatedAt',
            ellipsis: true,
            width: '140',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.updatedAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.updatedAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            ellipsis: true,
            width: 140,
            hidden: permissions && (permissions["USER-MANAGEMENT"] === "VIEW" || permissions["USER-MANAGEMENT"] === "OFF"),
            render: (text: any, record: any) => (
                <Space size={0}>
                    <Button
                        type="info"
                        size="small"
                        className="me-2"
                        icon={<i className="fa-solid fa-key me-2"></i>}
                        onClick={() => {
                            setDataMember(record)
                            toggleModalChangePassword();
                        }}
                    >
                        Change Password
                    </Button>
                    <Button
                        type="warning"
                        size="small"
                        icon={<i className="fa-solid fa-edit me-2"></i>}
                        onClick={() => { navigate(`edit/${record.username}`) }}
                    >
                        Edit
                    </Button>
                </Space>
            )
        }
    ].filter(item => !item.hidden);


    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data.totalCount > 0) {
            setDataSource(data.data)
        }
        return () => {
            setDataSource([])
        }
    }, [data, isLoading, setDataSource])

    useMemo(() => {
        mutate()
    }, [setDateRange, setPagination])// eslint-disable-line

    const handleSearch = () => {
        setSearch(filterText)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        mutate()
    }

    // useMemo(() => {
    //     if (searchParams.get("search")) {
    //         setFilterText(searchParams.get("search") || "")
    //         setSearchMember(searchParams.get("search") || "")
    //         mutate()
    //     }
    // }, [searchParams, setSearch])// eslint-disable-line

    return (
        <>
            <TitleBar title={<FormattedMessage id={'main.members'} defaultMessage={'Members'} />} subTitle={`${NumberWithCommas(data?.totalCount || 0)} items`} routes={routes} />
            <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} funClear={true} />
                <DateFilter onChangeDate={setDateRange} onDateActive={dateRange} />
            </div>
            <div className="d-flex justify-content-start mb-4">
                <Search onChangeSearch={setFilterText} onHandleSearch={handleSearch} filterText={filterText} />
                <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">Search</button>
            </div>
            {permissions && permissions["USER-MANAGEMENT"] === "EDIT" && (
                <div className="row mb-md-3 align-items-md-center">
                    <div className="mb-3 mb-md-0">
                        <Button
                            type="primary"
                            className="d-block ms-auto"
                            icon={<i className="fa-solid fa-plus me-3"></i>}
                            onClick={() => navigate(`add`)}>
                            Add Member
                        </Button>
                    </div>
                </div>
            )}
            <div className="box-white ant-box-table">
                <Table
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                    totalCount={data?.totalCount || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                />
            </div>
            {isDepositOpen && (
                <Deposit
                    isOpen={isDepositOpen}
                    close={() => toggleModalDeposit()}
                    onloadData={mutate}
                    username={dataMember?.username}
                />
            )}
            {isWithdrawOpen && (
                <Withdraw
                    isOpen={isWithdrawOpen}
                    close={() => toggleModalWithdraw()}
                    onloadData={mutate}
                    username={dataMember?.username}
                    maxWithdraw={dataMember?.balance}
                />
            )}
            {isChangePasswordOpen && (
                <ChangePassword
                    isOpen={isChangePasswordOpen}
                    close={() => toggleModalChangePassword()}
                    onloadData={mutate}
                    username={dataMember?.username}
                    role={'member'}
                />
            )}
            {isStatementOpen && (
                <Statement
                    isOpen={isStatementOpen}
                    close={() => toggleModalStatement()}
                    onloadData={mutate}
                    data={dataMember}
                />
            )}
            {isViewOpen && (
                <View
                    isOpen={isViewOpen}
                    close={() => toggleView()}
                    username={dataMember?.username}
                />
            )}
        </>
    );
};

export default Members