import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'
import {
    Form,
    Input,
    Row,
    Col,
    Table,
    PageHeader,
    Divider,
    Space,
    Button,
    Tabs,
    Select,
    Typography,
    InputNumber,
    Checkbox
} from 'antd'
// import type { TableRowSelection } from 'antd/es/table/interface'
import to from 'await-to-js'
import {
    MemberAdd
} from '~types/users'
import { ThunkDispatch } from '~types/store'
import TitleBar from '~components/Elements/TitleBar'
import Empty from '~components/Elements/Empty'
import { ApplicationProviderState, ApplicationState } from '~store/index'
import { requestAuthInit } from '~store/auth/auth.actions'
import { getGamesType } from '~utils/games'
import PatternNumber from '~utils/PatternNumber'
import NumberWithCommas from '~utils/NumberWithCommas'
import ServiceAPI from '~services/services'

const AddMember = () => {
    const [form] = Form.useForm();
    let navigate = useNavigate();
    const routes = [
        {
            path: '',
            breadcrumbName: 'Management',
        },
        {
            path: '/members',
            breadcrumbName: 'Members',
        },
        {
            path: '',
            breadcrumbName: 'Add Member',
        },
    ];

    const authState = useSelector((state: ApplicationState) => state.auth)
    const { providers: providerState } = useSelector((state: ApplicationProviderState) => state.providers)
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [indeterminate, setIndeterminate] = useState<boolean>(false)
    const [userProducts, setUserProducts] = useState<any>([])
    const [productsChecked, setProductsChecked] = useState<any>([])
    // const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])

    const insertData = async (values: MemberAdd) => {
        const [err, res] = await to(ServiceAPI.addMember(values))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
        } else {
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
            navigate(`/members`)
        }
    }

    const handleFormSubmit = async () => {
        await form.validateFields()
            .then(async (values: MemberAdd) => {
                setBtnLoading(true)
                delete values.confirm_password
                values.products = productsChecked
                insertData(values)
            })
            .catch((errorInfo) => {

            });
    }

    // const listProducts = (gameType: string) => {
    //     if (userProducts.length > 0) {
    //         const products = Object.keys(userProducts).map((key: string, index: any) => {
    //             if (!userProducts[key].enabled) {
    //                 return []
    //             }
    //             const labelCheckbox = userProducts[key]
    //             const res = getGames(labelCheckbox.key)
    //             res.enabled = userProducts[key].enabled
    //             res.ownPT = userProducts[key].ownPT
    //             return res
    //         })
    //         return products.filter((product: any) => product.type === gameType)
    //     }
    // }

    const handleCheckboxChangeAll = (e: any) => {
        onUpdateDataChecked(e.target.checked, 'all')
    }

    const handleCheckboxChange = (e: any, key: string) => {
        onUpdateDataChecked(e.target.checked, key)
    }

    const TabTypeProducts = Object.keys(getGamesType).map((item: string, index: any) => {
        // const productList: any = listProducts(item.toLocaleUpperCase())
        const productList: any = userProducts.filter((product: any) => product.type === item.toLocaleUpperCase() && product.enabled)
        const productType = (Object.values(getGamesType)[index]).toLocaleUpperCase()
        const columns = [
            {
                title: 'Game Name',
                dataIndex: 'name',
                key: `columns-name-${productType}`,
                render: (text: any, record: any) => {
                    return (
                        <Space>
                            <Checkbox
                                checked={Object(productsChecked)[`${record.key}`].enabled}
                                onChange={(e: any) => handleCheckboxChange(e, record.key)}
                            />
                            {record.name}
                        </Space>
                    )
                }
            },
            {
                title: 'Percentage',
                dataIndex: 'pt',
                key: `columns-pt-${productType}`,
                width: '200px',
                className: 'text-center',
                render: (text: any, record: any) => {
                    return `${record.ownPT}%`
                }
            }
        ];

        // const onSelectProducts = (selected: boolean, records: any) => {
        //     records.map((record: any, index: number) => {
        //         Object.assign(productsChecked, {
        //             [`${record.key}`]: {
        //                 'enabled': selected,
        //                 'givenPT': productsChecked[`${record.key}`].givenPT,
        //             }
        //         });
        //         return false
        //     })
        // }

        // const rowSelection: TableRowSelection<any> = {
        //     selectedRowKeys: selectedRowKeys.filter((item: any) => item.enabled).map((item: any) => item.key),
        //     onChange: (selectedRowKeys, selectedRows) => {
        //         // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        //         setSelectedRowKeys(selectedRows)
        //     },
        //     onSelect: (record, selected, selectedRows) => {
        //         // console.log(record, selected, selectedRows);
        //         onSelectProducts(selected, [record])
        //     },
        //     onSelectAll: (selected, selectedRows, changeRows) => {
        //         // console.log(selected, selectedRows, changeRows);
        //         onSelectProducts(selected, changeRows)
        //     },
        // };

        return {
            label: productType,
            key: index,
            children: (
                (productList && productList.length > 0) ? (
                    <Table
                        // rowSelection={rowSelection}
                        key={`table-${productType}`}
                        dataSource={productList}
                        columns={columns}
                        pagination={false}
                        bordered
                        size={'small'} />
                ) : <Empty key={`Empty ${productType}`} />
            ),
            disabled: productList && productList.length > 0 ? false : true
        };
    })


    const onUpdateDataChecked = (enabled: boolean, keyChange: any) => {
        let productsObj: object = {}
        Object.keys(productsChecked as object).forEach((key: any) => {
            if (key === keyChange || keyChange === "all") {
                Object.assign(productsObj, {
                    [`${key}`]: {
                        'enabled': enabled,
                        'givenPT': productsChecked[key].givenPT,
                    }
                });
            } else {
                Object.assign(productsObj, {
                    [`${key}`]: {
                        'enabled': productsChecked[key].enabled,
                        'givenPT': productsChecked[key].givenPT,
                    }
                });
            }
        })
        setProductsChecked(productsObj)
        setIndeterminate(Object.values(productsObj).some((item: any) => !item.enabled) ? false : true);
    }

    useEffect(() => {
        // let rowSelected: any[] = []
        if (form && providerState && Object.keys(providerState.data).length > 0) {
            form.resetFields();
            let productsObj: object = {}
            let authStateUserProduct: any = authState.data.user?.products ?
                Object.entries(authState.data.user?.products as object).filter(([k, v]) => v.enabled).map(([k, v]) => {
                    v.key = k
                    const dataProduct: any = providerState.data[v.key] as any
                    // rowSelected.push(v)

                    v.name = dataProduct.name
                    v.type = dataProduct.type
                    // rowSelected.push(v)
                    Object.assign(productsObj, {
                        [`${v.key}`]: {
                            'enabled': v.enabled,
                            'givenPT': v.ownPT,
                        }
                    });
                    return v
                })
                : []
            setProductsChecked(productsObj)
            setUserProducts(authStateUserProduct)
            setIndeterminate(Object.values(productsObj).every((item: any) => item.enabled));
            // setSelectedRowKeys(rowSelected)
        }
    }, [form, providerState, authState])

    return (
        <Fragment>
            <TitleBar title="Members" routes={routes} />
            <PageHeader
                className="site-page-header"
                onBack={() => navigate(`/members`)}
                title="Add Member"
            />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="addMember"
                    layout="vertical"
                    initialValues={{
                        currency: "THB"
                    }}>
                    <Typography.Title level={4} style={{ marginBottom: 16 }}>Member Information</Typography.Title>
                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="username"
                                label="Username"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                                hasFeedback>
                                <Input placeholder="Username" inputMode="text" autoComplete="new-username" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[{ required: true, message: 'Please input your name!' }]}
                                hasFeedback>
                                <Input placeholder="Name" inputMode="text" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                                hasFeedback>
                                <Input.Password placeholder="Password" inputMode="text" autoComplete="new-password" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="confirm_password"
                                label="Confirm Password"
                                dependencies={['password']}
                                rules={[
                                    { required: true, message: 'Please confirm your password!' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    })
                                ]}
                                hasFeedback>
                                <Input.Password placeholder="Confirm Password" inputMode="text" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="balance"
                                label="Credit"
                                rules={[
                                    { required: true, message: 'Please input your credit!' },
                                    { pattern: PatternNumber, message: 'Please enter numbers only!' },
                                    { type: 'number', min: 0, max: authState.data.user?.balance, message: `Please enter a number between 0 and ${NumberWithCommas(authState.data.user?.balance)}!` }
                                ]}
                                extra={`Min 0, Max ${NumberWithCommas(authState.data.user?.balance)}`}
                                hasFeedback>
                                <InputNumber
                                    placeholder="Credit"
                                    inputMode="numeric"
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/\.(?=\d{0,2}$)/g, ".")}
                                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                                    controls={false}
                                    onKeyPress={(event) => {
                                        if (!/^[0-9.,\b]+$/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="currency"
                                label="Currency"
                                rules={[{ required: true, message: 'Please select your currency!' }]}
                                hasFeedback>
                                <Select
                                    placeholder="Select Currency"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    <Select.Option value="THB">THB</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Typography.Title level={4} style={{ marginBottom: 16 }}>Contact Information</Typography.Title>
                    <Row gutter={16}>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="phone"
                                label="Phone Number"
                                rules={[{ required: false, message: 'Please input your phone number!' }]}
                            >
                                <Input placeholder="Phone Number" inputMode="tel" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="other"
                                label="Other Contact">
                                <Input placeholder="Other Contact" inputMode="text" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Typography.Title level={4} style={{ marginBottom: 16 }}>Product Settings</Typography.Title>
                    <Checkbox
                        checked={indeterminate}
                        onChange={(e: any) => handleCheckboxChangeAll(e)}>
                        Select All
                    </Checkbox>
                    <Tabs defaultActiveKey="0" items={TabTypeProducts} centered />
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large" className="text-white me-2"><i className="fa-solid fa-save me-2"></i>Save</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default AddMember