import useSWR from 'swr'
import ServiceAPI from '~services/services'
// import { DateRangeType } from '~types/IDateRange'
import { QueryParamsDate } from '~types/queryParams'

export const useFetchDashboard = (params: QueryParamsDate) => {
    const { data, mutate, error } = useSWR([`/dashboard/summary`, params], () => ServiceAPI.getDashboardSummary(params));

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchDashboard
