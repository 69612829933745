import React, { Fragment, useState, useEffect } from 'react'
import moment from 'moment'
import NumberWithCommas from '~utils/NumberWithCommas'
import TitleBar from '~components/Elements/TitleBar'
import FormattedMessage from '~components/Elements/FormattedMessage'
// import DatePickerRange from '~components/Elements/DatePickerRange'
// import DateFilter from '~components/Elements/DateFilter'
// import TableWithoutPagination from '~components/Elements/TableWithoutPagination'
// import BadgeComponent from '~components/Elements/BadgeComponent'
// import Search from '~components/Elements/Search'
// import useDateRangeEmpty from "~hooks/useDateRangeEmpty"
// import useDatePagination from "~hooks/useDatePagination"
import useFetchAnnouncer from "~hooks/useFetchAnnouncer"
import TableAnnouncer from '~components/Elements/TableAnnouncer'
// import ServiceAPI from '~services/services'

const Announcer = () => {
    const routes = [
        {
            path: '',
            breadcrumbName: 'Announcement',
        },
    ];

    const [dataSource, setDataSource] = useState<any>([])
    const { data, isLoading } = useFetchAnnouncer(10000)


    const columns = [
        {
            title: 'No.',
            dataIndex: 'number',
            width: 80,
            align: 'center',
            render: (text: any, record: any) => (
                <div>{record.number}</div>
            )
        },
        {
            title: 'Announcement',
            dataIndex: 'description',
            render: (text: any, record: any) => (
                <div>{record.description}</div>
            )
        },
        {
            title: 'Date/Time',
            dataIndex: 'updatedAt',
            render: (text: any, record: any) => (
                <div className="row-datetime">
                    <div><span>{moment(record.updatedAt).format('DD/MM/YYYY')}</span></div>
                    <div>{moment(record.updatedAt).format('HH:mm:ss')}</div>
                </div>
            )
        },
    ]


    useEffect(() => {
        setDataSource([])
        if (data !== undefined && data.data.length > 0) {
            const dataAnnouncer = data.data.map((item: any, index: number) => {
                return {
                    number: index + 1,
                    description: item.description,
                    updatedAt: item.updatedAt
                }
            })
            setDataSource(dataAnnouncer)
        }
        return () => {
            setDataSource([])
        }
    }, [data, isLoading])

    // useMemo(() => {
    //     mutate()
    // }, [setDateRange, setPagination])// eslint-disable-line

    // const handleSearch = () => {
    //     // setSearchAllData(filterText)
    //     setPagination({ page: 1, pageSize: pagination.pageSize })
    //     mutate()
    // }

    // useMemo(() => {
    //     if (searchParams.get("search")) {
    //         setFilterText(searchParams.get("search") || "")
    //         setSearchMember(searchParams.get("search") || "")
    //         mutate()
    //     }
    // }, [searchParams, setSearch])// eslint-disable-line

    return (
        <>
            <TitleBar title={<FormattedMessage id={'main.announcer'} defaultMessage={'Announcement'} />} subTitle={`${NumberWithCommas(dataSource.length || 0)} items`} routes={routes} />
            {/* <div className="d-lg-flex justify-content-lg-between d-md-flex justify-content-md-between mb-4">
                <DatePickerRange onChangeDate={setDateRange} onDateActive={dateRange} funClear={true} />
                <DateFilter onChangeDate={setDateRange} onDateActive={dateRange} />
            </div> */}
            {/* <div className="d-flex justify-content-start mb-4">
                <Search onChangeSearch={setFilterText} onHandleSearch={handleSearch} filterText={filterText} />
                <button onClick={handleSearch} className="btn btn-success btn-sm text-white ms-2">Search</button>
            </div> */}
            <div className="box-white ant-box-table">
                <TableAnnouncer
                    columns={columns}
                    dataSource={dataSource || []}
                    loading={isLoading}
                />
            </div>
        </>
    );
};

export default Announcer