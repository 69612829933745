import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'
import {
    Form,
    Input,
    Row,
    Col,
    PageHeader,
    Divider,
    Space,
    Button,
    Typography,
    Transfer,
} from 'antd'
import type { TransferDirection } from 'antd/es/transfer';
import to from 'await-to-js'
import {
    NotificationAdd
} from '~types/notification'
import TitleBar from '~components/Elements/TitleBar'
import { ApplicationState } from '~store/index'
import { requestAuthInit } from '~store/auth/auth.actions'
import { ThunkDispatch } from '~types/store'
import ServiceAPI from '~services/services'
import useFetchUsers from '~hooks/useFetchUsers'

interface RecordType {
    key: string;
    title: string;
    description: string;
    disabled: boolean;
    tag: string;
}


const AddNotification = () => {
    const [form] = Form.useForm();
    let navigate = useNavigate();
    const routes = [
        {
            path: '/notification',
            breadcrumbName: 'Notification',
        },
        {
            path: '',
            breadcrumbName: 'Add Notification',
        },
    ];

    const authState = useSelector((state: ApplicationState) => state.auth)
    const authStateUser = authState.data.user
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [targetKeys, setTargetKeys] = useState<string[]>();

    const { data, isLoading } = useFetchUsers(authStateUser ? authStateUser.username : "", "MEMBER")

    const [mockData, setMockData] = useState<RecordType[]>([]);

    const insertData = async (values: NotificationAdd) => {
        const [err, res] = await to(ServiceAPI.addNotification(values))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
        } else {
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
            navigate(`/notification`)
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                setBtnLoading(true)
                if (targetKeys === undefined) {
                    setBtnLoading(false)
                    toast.error("Please select your Member!")
                    return false
                }
                values.users = targetKeys
                insertData(values)
            })
            .catch((errorInfo) => {

            });
    }

    const getMock = (listMember: any) => {
        const tempMockData: any = [];
        listMember.forEach((element: any) => {
            const data = {
                key: element.username,
                title: element.username,
                description: element.name,
            };
            tempMockData.push(data);
        });
        setMockData(tempMockData);
    };

    const handleChange = (
        newTargetKeys: string[],
        direction: TransferDirection,
        moveKeys: string[],
    ) => {
        // console.log(newTargetKeys, direction, moveKeys);
        setTargetKeys(newTargetKeys);
    };

    const renderItem = (item: RecordType) => {
        const customLabel = (
            <span className="custom-item">
                {item.title} - {item.description}
            </span>
        );
        return {
            label: customLabel, // for displayed item
            value: item.title, // for title and filter matching
        };
    };


    useEffect(() => {
        if (form) {
            form.resetFields();
        }
    }, [form])// eslint-disable-line

    useEffect(() => {
        if (data !== undefined && data.totalCount > 0) {
            getMock(data.data);
        }
    }, [data, isLoading])


    return (
        <Fragment>
            <TitleBar title="Notification" routes={routes} />
            <PageHeader
                className="site-page-header"
                onBack={() => navigate(`/notification`)}
                title="Add Notification"
            />
            <div className="box-white ant-box-card">
                <Form
                    form={form}
                    name="addNotification"
                    layout="vertical">
                    <Typography.Title level={4} style={{ marginBottom: 16 }}>Notification Information</Typography.Title>
                    <Row gutter={16}>
                        <Col xs={24} sm={24}>
                            <Form.Item
                                name="description"
                                label="Description"
                                rules={[{ required: true, message: 'Please input your Description!' }]}
                                hasFeedback>
                                <Input.TextArea rows={4} placeholder="Description" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Typography.Title level={4} style={{ marginBottom: 16 }}>Member list</Typography.Title>

                    <Transfer
                        showSearch={true}
                        dataSource={mockData}
                        listStyle={{
                            width: "100%",
                            height: 600,
                        }}
                        targetKeys={targetKeys}
                        onChange={handleChange}
                        render={renderItem}
                    />
                    <Divider />
                    <div className="drawer-actions">
                        <Space>
                            <Button type="primary" htmlType="submit" disabled={btnLoading} onClick={handleFormSubmit} size="large" className="text-white me-2"><i className="fa-solid fa-save me-2"></i>Save</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default AddNotification