import useSWR from 'swr'
import ServiceAPI from '~services/services'

export const useFetchAnnouncer = (refreshInterval: number) => {
    const { data, mutate, error } = useSWR([`/announcer`], () => ServiceAPI.getAnnouncer(), { refreshInterval });

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchAnnouncer
