import { IRoute } from '~types/IRoute'
import Dashboard from '~views/Dashboard'
import { Agents, AddAgent, EditAgent } from '~views/Agents'
import { Members, AddMember, EditMember } from '~views/Members'
import { SubAccounts, AddSubAccount, EditSubAccount } from '~views/SubAccounts'
// import Transfer from '~views/Payment/Transfer'
import AccessLogs from '~views/SystemLogs/AccessLogs'
import PaymentLogs from '~views/SystemLogs/PaymentLogs'
import Feedbacks from '~views/Feedbacks'
import Announcer from '~views/Announcer'
import WinLoss from '~views/Reports/WinLoss'
import WinLossMember from '~views/Reports/WinLossMember'
import { Notification, AddNotification } from '~views/Notification'

export const menuItems: Array<IRoute> = [
        {
                key: "dashboard",
                title: "Home",
                path: "/dashboard",
                content: Dashboard,
                hide: false,
                permission_key: '',
                permission_menu: ''
        },
        {
                key: "agents",
                title: "Agents",
                path: "/agents",
                content: Agents,
                hide: false,
                permission_key: 'USER-MANAGEMENT',
                permission_menu: ''
        },
        {
                key: "members",
                title: "Members",
                path: "/members",
                content: Members,
                hide: false,
                permission_key: 'USER-MANAGEMENT',
                permission_menu: ''
        },
        {
                key: "sub_accounts",
                title: "Sub Accounts",
                path: "/sub-accounts",
                content: SubAccounts,
                hide: false,
                permission_key: 'SUB-USER-MANAGEMENT',
                permission_menu: ''
        },
        {
                key: "agents_add",
                title: "Add Agents",
                path: "/agents/add",
                content: AddAgent,
                hide: false,
                permission_key: 'USER-MANAGEMENT',
                permission_menu: 'EDIT'
        },
        {
                key: "agents_edit",
                title: "Edit Agents",
                path: "/agents/edit/:username",
                content: EditAgent,
                hide: false,
                permission_key: 'USER-MANAGEMENT',
                permission_menu: 'EDIT'
        },
        {
                key: "members_add",
                title: "Add Members",
                path: "/members/add",
                content: AddMember,
                hide: false,
                permission_key: 'USER-MANAGEMENT',
                permission_menu: 'EDIT'
        },
        {
                key: "members_edit",
                title: "Edit Members",
                path: "/members/edit/:username",
                content: EditMember,
                hide: false,
                permission_key: 'USER-MANAGEMENT',
                permission_menu: 'EDIT'
        },
        {
                key: "sub_accounts_add",
                title: "Add Sub Account",
                path: "/sub-accounts/add",
                content: AddSubAccount,
                hide: false,
                permission_key: 'SUB-USER-MANAGEMENT',
                permission_menu: 'EDIT'
        },
        {
                key: "sub_accounts_edit",
                title: "Edit Sub Account",
                path: "/sub-accounts/edit/:username",
                content: EditSubAccount,
                hide: false,
                permission_key: 'SUB-USER-MANAGEMENT',
                permission_menu: 'EDIT'
        },
        {
                key: "access_logs",
                title: "Access Logs",
                path: "/access-logs",
                content: AccessLogs,
                hide: false,
                permission_key: 'ACCESS-LOGS',
                permission_menu: ''
        },
        {
                key: "payment_logs",
                title: "Payment Logs",
                path: "/payment-logs",
                content: PaymentLogs,
                hide: false,
                permission_key: 'PAYMENT-LOGS',
                permission_menu: ''
        },
        {
                key: "feedbacks",
                title: "feedbacks",
                path: "/feedbacks",
                content: Feedbacks,
                hide: false,
                permission_key: 'FEEDBACK',
                permission_menu: ''
        },
        {
                key: "winloss",
                title: "winloss",
                path: "/winloss",
                content: WinLoss,
                hide: false,
                permission_key: '',
                permission_menu: ''
        },
        {
                key: "winloss_username",
                title: "winloss username",
                path: "/winloss/:username",
                content: WinLoss,
                hide: false,
                permission_key: '',
                permission_menu: ''
        },
        {
                key: "winloss_member",
                title: "winloss member",
                path: "/winloss/:username/member",
                content: WinLossMember,
                hide: false,
                permission_key: '',
                permission_menu: ''
        },
        {
                key: "notification",
                title: "Notification",
                path: "/notification",
                content: Notification,
                hide: false,
                permission_key: 'NOTIFICATION-MANAGEMENT',
                permission_menu: ''
        },
        {
                key: "notification_add",
                title: "Notification",
                path: "/notification/add",
                content: AddNotification,
                hide: false,
                permission_key: 'NOTIFICATION-MANAGEMENT',
                permission_menu: 'EDIT'
        },
        {
                key: "announcement",
                title: "Announcement",
                path: "/announcement",
                content: Announcer,
                hide: false,
                permission_key: 'ANNOUNCEMENT-MANAGEMENT',
                permission_menu: ''
        },
        // {
        //         key: "payment_transfer",
        //         title: "Deposit/Withdraw",
        //         path: "/payment-transfer",
        //         content: Transfer,
        //         hide: false,
        //         permission_key: '',
        // permission_menu: ''
        // },
]
