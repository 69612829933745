import React, { FC, useState, useEffect } from 'react'
import { Drawer } from 'antd'
import { toast } from 'react-toastify'
import to from 'await-to-js';
import ServiceAPI from '~services/services'
import Loading from '~components/Elements/Loading'
import EmptyComponents from '~components/Elements/Empty'
import ViewAgent from '~components/Users/ViewHistory/Agents'
import ViewMember from '~components/Users/ViewHistory/Members'

interface ModalProps {
    close: () => void
    isOpen: boolean
    idHistory: string
    username: string
    role: string
}

const ViewHistoryEdit: FC<ModalProps> = ({ isOpen, close, idHistory, username, role }) => {

    const [dataHistory, setDataHistory] = useState<any>([])
    const [historyLoading, setHistoryLoading] = useState<boolean>(false)

    const handleFetchViewHistory = async (username: string, idHistory: string) => {
        if (!username) {
            close()
        }
        setDataHistory([])
        setHistoryLoading(true)
        const [err, res] = await to(ServiceAPI.getViewHistoryEditUser(username, idHistory))
        if (err) {
            toast.error(err?.message as string)
            setHistoryLoading(false)
        } else {
            setDataHistory(res.data)
            setHistoryLoading(false)
        }
    }

    useEffect(() => {
        if (isOpen) {
            setDataHistory([])
            handleFetchViewHistory(username, idHistory)
        }
    }, [isOpen, idHistory, username]) // eslint-disable-line

    return (
        <Drawer
            title={`View history  edit`}
            onClose={close}
            open={isOpen}
            bodyStyle={{ paddingBottom: 80 }}
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .6}>
            {historyLoading ? <Loading /> : (
                dataHistory && Object.keys(dataHistory).length > 0
                    ? (role === 'AGENT' ? (
                        <ViewAgent dataSource={dataHistory} />
                    ) : (
                        role === 'MEMBER' ? (
                            <ViewMember dataSource={dataHistory} />
                        ) : (<EmptyComponents />)
                    ))
                    : (<EmptyComponents />)
            )}
        </Drawer>
    )
}

export default ViewHistoryEdit