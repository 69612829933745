import React, { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { useNavigate } from 'react-router-dom'
import { Modal, Button, Typography, Form, Input, Divider, Space, message, Result } from 'antd'
import { toast } from 'react-toastify'
import to from 'await-to-js';
import ServiceAPI from '~services/services'
import { requestAuthInit } from '~store/auth/auth.actions'
import { ThunkDispatch } from '~types/store'
import imageiOS from '~assets/img/app-store-badge-en.png'
import imageAndroid from '~assets/img/google-play-badge-en.png'
import { ActiveSecurity, InitializeSecurity } from '~types/security'
import Loading from '~components/Elements/Loading'
import { ApplicationState } from '~store/index'

interface ModalProps {
    close: () => void
    isOpen: boolean

    username: string
    onloadData?: () => void
}

const Modal2FASecurity: FC<ModalProps> = ({ isOpen, close, username, onloadData }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [initSecurity, setInitSecurity] = useState<InitializeSecurity>()
    const dispatch: ThunkDispatch<AnyAction> = useDispatch()
    const authState = useSelector((state: ApplicationState) => state.auth)
    const securityStatus = authState.data.user?.securityStatus
    const securityStatus_subUser = authState.data.subUser?.securityStatus


    const initializeSecurity = async () => {
        const [err, res] = await to(ServiceAPI.get2FASecurity())
        if (err) {
            toast.error(err?.message as string)
            setIsLoading(false)
        } else {
            setInitSecurity(res)
            setIsLoading(false)
        }
    }

    const active2FASecurity = async (values: ActiveSecurity) => {
        const [err, res] = await to(ServiceAPI.active2FASecurity(values))
        if (err) {
            toast.error(err?.message as string)
            setBtnLoading(false)
        } else {
            dispatch(requestAuthInit(false))
            toast.success(res.message)
            setBtnLoading(false)
            navigate('/logout')
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: ActiveSecurity) => {
                setBtnLoading(true)
                active2FASecurity(values)
            })
            .catch((errorInfo) => {

            });
    };


    useEffect(() => {
        if (isOpen) {
            form.resetFields();
            initializeSecurity()
        }
    }, [form, isOpen])


    return (
        <Modal
            title={`2FA Security`}
            open={isOpen}
            onCancel={close}
            onOk={handleFormSubmit}
            centered
            width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .5}
            footer={[
                <Button key="submit" type="primary" htmlType="submit" disabled={(securityStatus === 'ACTIVE' || securityStatus_subUser === 'ACTIVE') ? true : btnLoading} onClick={handleFormSubmit} size="large"><i className="fa-solid fa-save me-2"></i>Save</Button>,
                <Button key="close" onClick={close}>Close</Button>,
            ]}>
            <Typography.Title level={5}>
                Username : {username}
            </Typography.Title>
            {(securityStatus === 'ACTIVE' || securityStatus_subUser === 'ACTIVE') ?
                <Result
                    status="success"
                    title="2FA Security"
                    subTitle="You have already enabled 2FA Security."
                />
                : isLoading ? <Loading /> :
                    <>
                        <Divider />
                        <Divider orientation="left" orientationMargin="0">
                            1. Download and install Google Authenticator
                        </Divider>
                        <Space>
                            <a href="https://apps.apple.com/th/app/google-authenticator/id388497605" target="_blank" rel="noreferrer">
                                <img src={imageiOS} alt="ios_store" style={{ width: '150px', padding: '7px' }} />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=th&gl=US" target="_blank" rel="noreferrer">
                                <img src={imageAndroid} alt="play_store" style={{ width: '180px' }} />
                            </a>
                        </Space>
                        <Divider orientation="left" orientationMargin="0">
                            2. Open the application to scan the QR CODE and store the backup code.
                        </Divider>
                        <p>Scan the QR CODE in the Google Authenticator application.</p>
                        {initSecurity?.data.qrcodeString ? <img src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${initSecurity?.data.qrcodeString}`} alt="qrcode" style={{ width: '150px', paddingBottom: '10px' }} /> : '-'}
                        <p>If you can't scan the QR CODE, please manually enter the key below in the application.</p>
                        <Space>
                            <span style={{ color: '#0d6efd', fontSize: '22px' }}>{initSecurity?.data.key}</span>
                            <Button type="link" onClick={() => {
                                navigator.clipboard.writeText(initSecurity?.data.key || "")
                                message.success('Copy successfully!');
                            }}>
                                Copy
                            </Button>
                        </Space>
                        <p style={{ color: '#ff0000' }}>Please keep this key on the board. This key will allow you to recover 2-Factor authentication. in case of lost phone</p>
                        <Divider orientation="left" orientationMargin="0">
                            3. Enter the 6-digit code obtained from the application.
                        </Divider>
                        <Form
                            form={form}
                            name="2fa"
                            layout="vertical"
                            hideRequiredMark
                            wrapperCol={{ span: 8 }}
                        >
                            <Form.Item
                                name="otp"
                                label="OTP"
                                rules={[{ required: true, message: 'Please enter the 6-digit code received from the application.' }]}
                                hasFeedback
                            >
                                <Input placeholder="OTP" />
                            </Form.Item>
                        </Form>
                    </>
            }
        </Modal>
    )
}

export default Modal2FASecurity