import React, { FC, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import NumberWithCommas from '~utils/NumberWithCommas';
import Loading from '../Elements/Loading';

interface AgentWinlossProps {
    dataSource: any
    isLoading: boolean
}

const AgentWinloss: FC<AgentWinlossProps> = ({ dataSource, isLoading }) => {
    const [dataAgentWinloss, setDataAgentWinloss] = useState<any>([])
    const AgentWinlossChart = {
        options: {
            series: [{
                name: 'Amount',
                data: dataAgentWinloss,
                color: '#17c5a2',
            }],
            chart: {
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            colors: ['#52D4BA', '#FF4267'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: false,
            },
            tooltip: {
                theme: 'apexcharts-tooltip',
                x: {
                    formatter: function (val: any) {
                        return 'Time ' + val + ''
                    },
                },
                y: {
                    formatter: function (val: any) {
                        return NumberWithCommas(val, 2) + ' Baht'
                    },
                }
            },
            xaxis: {
                categories: [
                    '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00',
                    '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00',
                    '22:00', '23:00',
                ],
                labels: {
                    rotate: -60,
                    style: {
                        cssClass: 'apexcharts-label'
                    },
                }
            },
            yaxis: {
                labels: {
                    style: {
                        cssClass: 'apexcharts-label'
                    },
                    formatter: function (val: any) {
                        return NumberWithCommas(val, 0)
                    },
                }
            },
            grid: {
                borderColor: 'var(--line)',
            },
            legend: {
                labels: {
                    colors: ['var(--text-secondary)']
                }
            }
        }
    };

    useEffect(() => {
        if (dataSource !== undefined && Object.keys(dataSource).length > 0) {
            const agentWinloss = Object.values(dataSource).map((item: any) => item.agentWinloss)
            setDataAgentWinloss(agentWinloss)
        }

    }, [dataSource])

    return (
        <div className="box-white ant-box-table p-md-2 pt-3 pt-md-4 pb-md-0 mb-2 mb-lg-0">
            <p className="text-center mb-0">Agent Winloss</p>
            {isLoading ? <Loading /> :
                <Chart
                    type="bar"
                    options={AgentWinlossChart.options as object}
                    series={AgentWinlossChart.options.series}
                />
            }
        </div>
    )
}

export default AgentWinloss