import React, { FC, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { ThemeContext } from "~contexts/ThemeContext";
import { ScreenContext } from "~contexts/ScreenContext";
import { Button, Divider, Dropdown, Menu, message, Modal, Switch, Tooltip, Typography } from 'antd';
import type { MenuProps } from 'antd';
import { UserOutlined, ExclamationCircleOutlined, CopyOutlined } from '@ant-design/icons';
import styled from 'styled-components'
import FormattedMessage from './FormattedMessage'
import { useThemeSwitcher } from "react-css-theme-switcher"
import { useTranslation } from 'react-i18next'
import { availableLanguages } from '~utils/i18n'
import ProfileModal from '~components/Users/Profile'
import ChangePasswordAccount from '~components/Users/ChangePasswordAccount'
import Modal2FASecurity from '~components/2fa/Modal'
import { ApplicationState } from '~store/index'
import { useSelector } from 'react-redux'
import to from 'await-to-js'
import ServiceAPI from '~services/services'
import { toast } from 'react-toastify'


const { Text } = Typography

interface ProfileButtonProps {
    className?: string
    username: string
    role?: string
}

type MenuItem = Required<MenuProps>['items'][number];
const ProfileButton: FC<ProfileButtonProps> = ({ className, username, role }) => {
    const { theme, toggleTheme } = useContext(ThemeContext)
    const { screen, toggleScreen } = useContext(ScreenContext)
    // const [visible, setVisible] = useState(false)


    const authState = useSelector((state: ApplicationState) => state.auth)

    // const onVisibleChange = (visible: boolean) => setVisible(visible)
    const { i18n } = useTranslation()
    const [current, setCurrent] = React.useState(i18n.language);
    const { switcher, themes } = useThemeSwitcher();

    const toggleTheme2 = (isChecked: boolean) => {
        switcher({ theme: isChecked ? themes.dark : themes.light });
    }


    const [isProfileModalOpen, setIsProfileModalOpen] = useState<boolean>(false);
    const toggleModalProfile = () => setIsProfileModalOpen(!isProfileModalOpen)

    const [isChangePasswordOpen, setIsChangePasswordOpen] = useState<boolean>(false);
    const toggleModalChangePassword = () => setIsChangePasswordOpen(!isChangePasswordOpen)

    const [is2FAOpen, setIs2FAOpen] = useState<boolean>(false);
    const toggleModal2FA = () => setIs2FAOpen(!is2FAOpen)

    const handleCopy = (values: string) => {
        navigator.clipboard.writeText(values)
        message.success('Copy successfully');
    }

    const toggleRefreshAPIKey = async (username: string) => {
        Modal.confirm({
            title: `You want to refresh API Key #${username}?`,
            icon: <ExclamationCircleOutlined />,
            content: `Make sure you want to refresh API Key #${username}`,
            okText: 'Yes',
            cancelText: 'No',
            centered: true,
            onOk() {
                (async () => {
                    const [err, res] = await to(ServiceAPI.refreshAPIKey())
                    if (err) {
                        toast.error(err?.message as string)
                    } else {
                        Modal.success({
                            title: res.message,
                            content: (
                                <>
                                    {res.apiKey}
                                    <Tooltip title="Copy APIKey">
                                        <Button icon={<CopyOutlined />} className="ms-2 mt2" disabled={res.apiKey ? false : true} onClick={() => handleCopy(res.apiKey)} />
                                    </Tooltip>
                                </>
                            ),
                            centered: true,
                        })
                    }
                })();
            },
            onCancel() {

            },
        });
    };


    const getItem = (
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem => {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    const itemLang: any[] = Object.entries(availableLanguages).map((language: any) => {
        return getItem(
            <div onClick={() => { i18n.changeLanguage(language[0]); setCurrent(language[0]) }}>
                {language[1]}
            </div>, language[0])
    })

    const items: MenuItem[] = [
        getItem(
            <div onClick={toggleModalProfile}>
                <FormattedMessage id={'menu-profile'} defaultMessage={'Profile'} />
            </div>, "m-0"),
        getItem(
            <div onClick={toggleModalChangePassword}>
                <FormattedMessage id={'menu-change-password'} defaultMessage={'Change Password'} />
            </div>, "m-1"),
        getItem(
            <div onClick={toggleModal2FA}>
                <FormattedMessage id={'menu-2fa'} defaultMessage={'2FA Security'} />
            </div>, "m-2"),
        getItem(
            <div onClick={() => toggleRefreshAPIKey(authState.data.user?.username || "")}>
                <FormattedMessage id={'menu-refresh-apikey'} defaultMessage={'Refresh APIKey'} />
            </div>, "m-3"),
        getItem(<FormattedMessage id={'menu-translation'} defaultMessage="Language" />, 'm-4', null, itemLang),
        getItem(
            <div className="d-flex">
                <span><FormattedMessage id={'mode'} defaultMessage={'Mode'} /></span>
                <Switch
                    className='ms-auto'
                    checkedChildren={<i className="fad fa-moon"></i>}
                    unCheckedChildren={<i className="fa fa-sun"></i>}
                    checked={theme === "dark" ? true : false}
                    onClick={(e) => {
                        toggleTheme(e)
                        toggleTheme2(e)
                    }}
                />
            </div>
            , 'm-5'),
        getItem(
            <div className="d-flex">
                <span><FormattedMessage id={'full-screen'} defaultMessage={'Full Screen'} /></span>
                <Switch
                    className='ms-auto'
                    checkedChildren={"Open"}
                    unCheckedChildren={"Close"}
                    defaultChecked={screen === "full" ? true : false}
                    onClick={(e) => {
                        toggleScreen(e)
                    }}
                />
            </div>
            , 'm-6'),
        getItem(
            <Divider className="mt-2 mb-0" />
            , 'm-7'),
        getItem(
            <Link to="/logout">
                <Text type="danger">
                    <i className="fas fa-sign-out me-2"></i> <FormattedMessage id="logout" defaultMessage="Logout" />
                </Text>
            </Link>
            , 'm-8'),
    ];
    const menu = (
        <Menu className="profile-link-hover" selectedKeys={[current]} items={items} />
    );
    return (
        <>
            <div className={"profile-button " + className}>
                <Dropdown overlay={menu} trigger={["click"]} placement="bottom" >
                    <Profile className="ant-dropdown-link">
                        <div className="d-lg-none">
                            <UserOutlined className="profile-icon" style={{ fontSize: '20px', padding: '4px 8px' }} />
                        </div>
                        <div className="profile-wrapper d-none d-lg-flex">
                            <div className="profile-char">
                                <span>{username?.charAt(0)}</span>
                            </div>
                            <div className="profile-info">
                                <div className="profile-username">{username}</div>
                                <small className="profile-permission">{role?.toLocaleUpperCase()}</small>
                            </div>
                        </div>
                        <i className="far fa-chevron-down profile-chevron-down"></i>
                    </Profile>
                </Dropdown>
            </div>
            {isProfileModalOpen &&
                <ProfileModal
                    isOpen={isProfileModalOpen}
                    close={() => toggleModalProfile()}
                />
            }
            {isChangePasswordOpen &&
                <ChangePasswordAccount
                    isOpen={isChangePasswordOpen}
                    close={toggleModalChangePassword}
                    username={username}
                />
            }
            {is2FAOpen &&
                <Modal2FASecurity
                    isOpen={is2FAOpen}
                    close={toggleModal2FA}
                    username={username}
                />
            }
        </>
    )
}

export default ProfileButton

const Profile = styled.div`
    display: flex;
    align-items: center;
    line-height: 1.25;
`