import React, { useState, useEffect } from 'react'
import moment from 'moment'

const DateTimeNow = () => {
    const [dateNow, setDateNow] = useState(moment().format('DD/MM/YYYY'))
    const [timeNow, setTimeNow] = useState(moment().format('HH:mm:ss Z'))

    useEffect(() => {
        const interval = setInterval(() => {
            setDateNow(moment().format('DD/MM/YYYY'))
            setTimeNow(moment().format('HH:mm:ss Z'))
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, [])

    return (
        <div className="datetime-card">
            <i className="fas fa-timer me-2"></i>
            <div className="datetime-card-text">
                <div className="datetime-card-date">{dateNow}</div>
                <div>{timeNow}</div>
            </div>
        </div>
    );
}

export default DateTimeNow