import React, { FC } from 'react'
import styled from 'styled-components'

interface BadgeProps {
    text: string
    color?: string
    className?: string
}

const BadgeComponent: FC<BadgeProps> = ({ text, color, className }) => {
    return (
        <Badge
            bgColor={color || 'rgba(0,0,0,0)'}
            className={'badge badge-large ' + (className || '')}>
            {text}
        </Badge>
    )
}

export default BadgeComponent

const Badge = styled.span<{ bgColor: string }>`
	color: rgb(${props => props.bgColor});
    background: rgba(${props => props.bgColor}, 0.1);
`