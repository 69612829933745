import useSWR from 'swr'
import ServiceAPI from '~services/services'
// import { DateRangeType } from '~types/IDateRange'
import { QueryParams } from '~types/queryParams'

export const useFetchPaymentLogs = (params: QueryParams, refreshInterval: number) => {
    const { data, mutate, error } = useSWR([`/logs/payment-logs?limit=${params?.limit}&page=${params?.page}`, params], () =>
        ServiceAPI.getPaymentLogs(params), { refreshInterval });

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

export default useFetchPaymentLogs
